import { Button, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import apiMo from "../../axios/axios"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Modal, Box } from '@mui/material'
import { useLoginStore } from "../../store/zustand"
import EditIcon from '@mui/icons-material/Edit';

/*interface IBuscarArticulo{
    id_articulo: number; 
    codigo_articulo: string; 
    nombre_articulo: string; 
    descripcion_articulo: string; 
    articulo_contable: string;
    cantidad : string | number;
}*/

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Articulos = () => {

    const { loginStore } = useLoginStore()

    //const [buscador, setBuscador] = useState<IBuscarArticulo>({id_articulo: 0, codigo_articulo: '', nombre_articulo: '', descripcion_articulo: '', articulo_contable: '', cantidad : '' || 0})
    const [articulos, setArticulos] = useState([])
    //const [nombreArticulo, setNombreArticulo] = useState('')
    const [mOpen, setMopen] = useState(false)
    const [movimientos, setMovimientos] = useState([])
    const [mEditar, setMeditar] = useState(false)

    const [nArticuloEdit, setNarticuloEdit] = useState('')
    const [dArticuloEdit, setDarticuloEdit] = useState('')
    const [pArticuloEdit, setParticuloEdit] = useState(0)
    const [articuloEditar, setArticuloEditar] = useState(0)

    const [spEditarArticulo, setSpEditarArticulo] = useState(false)
    const [spEliminarArticulo, setSpEliminarArticulo] = useState(false)

    const buscarArticulo = async(e: any) => {
        if(e.target.value <= 3) articulosCompletos()

        try {
            if(e.target.value.length <= 3) return

            /*setBuscador({
                ...buscador,
                [e.target.name] : e.target.value
            })*/

            const bnombre = btoa(e.target.value)
            const coincidencias = await apiMo.get(`/buscar-articulo-coincidencia/${bnombre}`,{
                headers: {
                    'Authorization' : `Bearer ${loginStore.token}`,
                    'Accept': 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })

            if(coincidencias.data.length === 0){
                toast.error('No se encontraron coincidencias!', toast_config)
            }

            setArticulos(coincidencias.data)

        } catch (error:any) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.error){
                    msg_error = error.response.data.error
                }else{
                    msg_error = 'Ocurrió un error al obtener articulos en coincidencia, favor de volver a cargar!'
                }
            }
            //mensaje de toastify
            toast.error(msg_error, toast_config)
        }
        
        

    }

    const articulosCompletos = () => {

        const buscar_articulos = async() => {

            try {
                const buscando = await apiMo.get('/obtener-articulos',{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })

                setArticulos(buscando.data)

            } catch (error : any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al obtener articulos, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

            

        }

        buscar_articulos()

    }

    const buscarMovimientosArticulo = (id: number) => {

        const movimientos = async() => {

            try {

                const ia = btoa(id.toString())

                const movimientos_articulo = await apiMo(`/movimientos-articulo/${ia}`,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                const meses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE']

                movimientos_articulo.data.forEach((mov : {fecha_movimiento: string}) => {
                        const d = new Date(mov.fecha_movimiento)
                        mov.fecha_movimiento = `${d.getDate().toString().padStart(2, '0')}-${meses[d.getMonth()]}-${d.getFullYear()} ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}`
                })

                setMovimientos(movimientos_articulo.data)
                setMopen(true)

            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al buscar movimientos de articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

        }

        movimientos()

    }

    useEffect(() => {
        articulosCompletos()
    }, [])


    const buscarArticuloEditar = (articulo : number) => {
        const buscar = async() => {

            try {
                setArticuloEditar(articulo)
                const b64articulo = btoa(articulo.toString())

                const editando = await apiMo.get(`/obtener-articulo/${b64articulo}`,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })


                setNarticuloEdit(editando.data.nombre)
                setDarticuloEdit(editando.data.descripcion)
                setParticuloEdit(editando.data.precio)

                setMeditar(true)

            } catch (error:any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al buscar información de articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                setMeditar(false)
            }

            

        }

        buscar()
    }

    const editarArticulo = (articulo: number) => {

        const editar = async() => {

            try {
                setSpEditarArticulo(true)
                const b64articulo = btoa(articulo.toString())

                const articulo_edit = {
                    nombre: nArticuloEdit,
                    descripcion: dArticuloEdit,
                    precio : pArticuloEdit
                }

                const editando = await apiMo.put(`/actualizar-articulo/${b64articulo}`,articulo_edit,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })
                
                articulosCompletos()

                setSpEditarArticulo(false)
                setMeditar(false)
                toast.success(editando.data.mensaje, toast_config)
                    
            } catch (error:any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al editar articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                setMeditar(false)   
                setSpEditarArticulo(false) 
            }

        }

        editar()
        

    }

    const eliminarArticulo = (articulo : number) => {

        const eliminar = async() => {

            try {
                setSpEliminarArticulo(true)
                const b64articulo = btoa(articulo.toString())

                const eliminar = {
                    activo: 0
                }

                const eliminando = await apiMo.put(`/actualizar-articulo/${b64articulo}`,eliminar,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                articulosCompletos()

                setSpEliminarArticulo(false)
                setMeditar(false)   
                toast.success(eliminando.data.mensaje, toast_config)
                



            } catch (error:any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al eliminar articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                setMeditar(false)   
                setSpEliminarArticulo(false) 
            }

        }

        eliminar()

    }

    return(
        <>
            <div className="col-md-12 animation-layout">
                <div className="card shadow">
                    <div className="card-body ">
                        <h3>Consultar Articulos</h3>
                        <hr />
                        <div className="row">
                            {/*<div className="col-md-3 d-flex justify-content-center ">
                                <TextField id="outlined-basic" style={{'width' : '100%'}} className="mb-3" onChange={buscarArticulo} name="codigo" size="small" label="Codigo" variant="outlined" />
                            </div>*/}
                            <div className="col-md-3 d-flex justify-content-center">
                                <TextField id="outlined-basic" style={{'width' : '100%'}} className="mb-3" onChange={buscarArticulo} name="nombre" size="small" label="Nombre" variant="outlined" />
                            </div>
                        </div>
                        <hr />

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell>Codigo</StyledTableCell>
                                    <StyledTableCell align="center">Nombre</StyledTableCell>
                                    <StyledTableCell align="center">Descripcion</StyledTableCell>
                                    <StyledTableCell align="center">Contable</StyledTableCell>
                                    <StyledTableCell align="center">Cantidad</StyledTableCell>
                                    <StyledTableCell align="center">Movimientos</StyledTableCell>
                                    { loginStore.jtoken.id_rol === 1 && 
                                        <StyledTableCell align="center">Editar</StyledTableCell>
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {articulos && articulos.map((articulo: {id_articulo: number; codigo_articulo: string; nombre_articulo: string; descripcion_articulo: string; articulo_contable: string; cantidad_inventario : string | number;}) => (
                                    <StyledTableRow key={articulo.id_articulo}>
                                        <StyledTableCell component="th" scope="row">
                                            {articulo.codigo_articulo}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{articulo.nombre_articulo}</StyledTableCell>
                                        <StyledTableCell align="center">{articulo.descripcion_articulo}</StyledTableCell>
                                        <StyledTableCell align="center">{articulo.articulo_contable}</StyledTableCell>
                                        <StyledTableCell align="center">{articulo.articulo_contable === 'CONTABLE' ? articulo.cantidad_inventario : 'NO APLICA'}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Inventory2OutlinedIcon  style={{'cursor': 'pointer'}} onClick={() => buscarMovimientosArticulo(articulo.id_articulo)}/>
                                        </StyledTableCell>
                                        { loginStore.jtoken.id_rol === 1 && 
                                            <StyledTableCell  align="center" >
                                                <EditIcon style={{'cursor': 'pointer'}} onClick={() => buscarArticuloEditar(articulo.id_articulo)} />
                                            </StyledTableCell>
                                        }
                                    </StyledTableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {mOpen && 
                            <Modal
                                open={mOpen}
                                onClose={() => setMopen(false)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                            <Box sx={style}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Nombre Articulo</StyledTableCell>
                                            <StyledTableCell align="right">Cantidad</StyledTableCell>
                                            <StyledTableCell align="right">Movimiento</StyledTableCell>
                                            <StyledTableCell align="right">Fecha</StyledTableCell>
                                            <StyledTableCell align="right">Usuario</StyledTableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {movimientos && movimientos.length > 0 ? movimientos.map((movimiento: {id_articulo: number; nombre: string; cantidad_inventario: number; movimiento: string; fecha_movimiento: string; nombre_usuario: string; }) => (
                                            <StyledTableRow key={movimiento.fecha_movimiento} >
                                                <StyledTableCell component="th" scope="row">
                                                    {movimiento.nombre}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{movimiento.cantidad_inventario}</StyledTableCell>
                                                <StyledTableCell align="right" style={movimiento.movimiento === 'ENTRADA' ? {'backgroundColor': 'green', 'color':'whitesmoke'} : {'backgroundColor': 'red', 'color':'whitesmoke'}}>{movimiento.movimiento === 'ENTRADA' ? movimiento.movimiento : `-${movimiento.movimiento}`}</StyledTableCell>
                                                <StyledTableCell align="right">{movimiento.fecha_movimiento}</StyledTableCell>
                                                <StyledTableCell align="right">{movimiento.nombre_usuario}</StyledTableCell>
                                            </StyledTableRow>
                                        )): 
                                        <StyledTableRow key={'noseencontro'} >
                                            <StyledTableCell colSpan={5} align="center" className="text-danger fw-bold text-center">No se encontraron movimientos de este articulo.</StyledTableCell>
                                                    
                                        </StyledTableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                          </Modal>
                        }

                        <Modal
                            open={mEditar}
                            onClose={() => setMeditar(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4 d-flex justify-content-center mb-3">
                                                <TextField variant="standard" style={{'width': '75%'}} label="Nombre" value={nArticuloEdit} onChange={(e) => setNarticuloEdit(e.target.value.toUpperCase())} />
                                            </div>
                                            <div className="col-md-4 d-flex justify-content-center mb-3">
                                                <TextField variant="standard" style={{'width': '75%'}} label="Descripcion" value={dArticuloEdit} onChange={(e) => setDarticuloEdit(e.target.value.toUpperCase())} />
                                            </div>
                                            <div className="col-md-4 d-flex justify-content-center mb-3">
                                                <TextField type="number" style={{'width': '75%'}} variant="standard" label="Precio" value={pArticuloEdit} onChange={(e) => setParticuloEdit(Number(e.target.value))}/>
                                            </div>
                                            <div className="col-md-6 d-flex justify-content-center mb-3">
                                                <Button disabled={spEditarArticulo}  style={{'width': '75%'}} variant="contained" onClick={() => editarArticulo(articuloEditar)}>
                                                    Editar
                                                    {spEditarArticulo && 
                                                        <div className="spinner-border text-light spinner-border-sm" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                </Button>
                                            </div>
                                            <div className="col-md-6 d-flex justify-content-center mb-3">
                                                <Button disabled={spEliminarArticulo} style={{'width': '75%'}} variant="contained" color="error" onClick={() => eliminarArticulo(articuloEditar)}>
                                                    Eliminar
                                                    {spEliminarArticulo && 
                                                        <div className="spinner-border text-light spinner-border-sm" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </Box>
                        </Modal>

                        <hr />
                    </div>
                </div>
                <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    )

}

export default Articulos