import {create} from "zustand";
import jwtDecode from "jwt-decode"

type Store = {
    loginStore : {
        auth: boolean,
        token: string,
        jtoken : {usuario: string, id_usuario:number, id_rol: number, nombre_completo: string}},
    guardarAuth: (loginStore: object) => void,
    limpiarAuth: () => void
}

const token = localStorage.getItem('token')

export const useLoginStore = create<Store>()((set) => ({
    loginStore : {
        auth: token ? true : false,
        token: token || '',
        jtoken : token ? jwtDecode(token) : {usuario: '', id_usuario: 0, id_rol: 0, nombre_completo: ''} 
    },
    guardarAuth: (loginStore: object) => set(loginStore),
    limpiarAuth: () => set({ 
        loginStore: {
            auth: false,
            token: '',
            jtoken: {usuario: '', id_usuario: 0, id_rol: 0, nombre_completo: ''}
        } 
    })
}))