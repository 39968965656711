
const VisionMision = () => {
    return(
        <div className="container my-5">
            <div className="row text-uppercase d-flex align-items-center">
                <div className="col-md-6 text-end mision">
                    <h1>Misión</h1>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
                <div className="col-md-6 text-center">
                    <img src="/img/mision.png" alt="" className="maxw" width={'300px'} />
                </div>
                <div className="col-md-6 text-center">
                    <img src="/img/vision.png" alt="" className="maxw" width={'300px'} />
                </div>
                <div className="col-md-6 mision">
                    <h1>Visión</h1>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
            </div>
        </div>
    ) 
}

export default VisionMision

