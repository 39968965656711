
const Principal = () => {
    return(
        
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">

                </div>
                <div className="col-md-10 text-end m-0 p-0">
                    <img src="/img/principal.png" alt="" className="img-fluid " />
                </div>
                <div className="col-md-12 mx-0 px-0 py-2 text-center titulo1 ">
                    <span className="fw-bold">CONTAMOS CON VARIEDAD DE MARCAS</span>   
                </div>
                <div className="col-md-12 mx-0 px-0">
                    <div className="container-fluid mx-0 px-0">
                        <img src="/img/marcas.png" alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Principal