
import "./Header.style.css"

const Header  = () => {
    return(
        <div className="container-fluid border-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <img src="/img/logo.jpg" alt="" className="logo" width={'125px'} />
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <p className="fw-bold pmenu">Inicio</p>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Header