import { Button, TextField, FormControl, Select, MenuItem, InputLabel } from "@mui/material"
import apiMo from '../../axios/axios';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../store/zustand"

interface IPaquete{
    nombre: string,
    descripcion: string,
    armazon: number,
    micas: number,
    accesorios: number,
    precio: number
}

//CREAR APARTADO PARA MODIFICAR INFORMACION DE PAQUETE


const AltaPaquete = () => {

    const { loginStore } = useLoginStore()

    const [datos, setDatos] = useState<IPaquete>({
        nombre: '',
        descripcion: '',
        armazon: 0,
        micas: 0,
        accesorios: 0,
        precio: 0
    })

    const [catArmazones, setCatArmazones] = useState([])
    const [catMicas, setCatMicas] = useState([])
    const [catAccesorios, setCatAccesorios] = useState([])

    const [armazon, setArmazon] = useState(0)
    const [micas, setMicas] = useState(0)
    const [accesorios, setAccesorios] = useState(0)

    const [nombre, setNombre] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [precio, setPrecio] = useState(0)

    const [spAltaPaquete, setSpAltaPaquete] = useState(false)
    const navigate = useNavigate()


    const leerCatalogos = () => {

        const catalogos = async() => {

            try {
                const cargando_cats = await apiMo.get('/catalogos-alta-paquete',{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setCatArmazones(cargando_cats.data.armazones)
                setCatMicas(cargando_cats.data.micas)
                setCatAccesorios(cargando_cats.data.accesorios)

            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }
            
        }

        catalogos()

    }

    useEffect(()=>{
        leerCatalogos()
    }, [])



    const leerDatos = (e: any) => {
        const mayus = e.target.value.toUpperCase()

        if(e.target.name === 'nombre'){
            setNombre(mayus)
        }

        if(e.target.name === 'descripcion'){
            setDescripcion(mayus)
        }

        if(e.target.name === 'precio'){
            setPrecio(e.target.value)
        }

        setDatos({
            ...datos,
            [e.target.name] : e.target.value
        })
    }

    const guardarPaquete = () =>{

        const altaPaquete = async() => {

            try {
                let key: string = ''

                if(!datos.nombre) key = 'nombre'
                if(!datos.descripcion) key = 'descripcion'
                if(datos.precio === 0) key = 'precio'
                if(armazon === 0) key = 'armazon'
                if(micas === 0) key = 'micas'
                if(accesorios === 0) key = 'accesorios'

                if(key){
                    return toast.error(`El campo ${key} es obligatorio`, toast_config)
                }

                Swal.fire({
                    title: 'Estas seguro?',
                    text: "Se agregara paquete!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, agregar!'
                  }).then(async(result) => {
                    if(result.isConfirmed){
                        setSpAltaPaquete(true)
                        const paquete = {
                            nombre: datos.nombre,
                            descripcion: datos.descripcion,
                            precio: datos.precio,
                            armazon: armazon,
                            micas: micas,
                            accesorio: accesorios,
                        }
        
                        const alta_paquete = await apiMo.post('/crear-paquete',paquete,{
                            headers: {
                                'Authorization' : `Bearer ${loginStore.token}`,
                                'Accept': 'application/x-www-form-urlencoded',
                                'Content-Type': 'application/x-www-form-urlencoded',
                            }
                        })

                        setSpAltaPaquete(false)

                        Swal.fire({
                            icon: 'success',
                            title: 'Enhorabuena!',
                            text: alta_paquete.data.mensaje
                        }).then(result => {
                            navigate('/home')
                        })

                    }
                }).catch((error) => {
                    let msg_error = ''
                    if(error.response){
                        if(error.response.data.error){
                            msg_error = error.response.data.error
                        }else{
                                msg_error = 'Ocurrió un error al guardar paquete, favor de volver a intentar!'
                        }
                    }
    
                    //mensaje de toastify
                    toast.error(msg_error, toast_config)
                    setSpAltaPaquete(false)
                });


            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar paquete elegible, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                setSpAltaPaquete(false)
            }

            

        }

        altaPaquete()

    }

    return(
        <>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                        <h3>Alta de Paquetes Seleccionados</h3>
                        <hr />
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <TextField onChange={leerDatos} value={nombre} name="nombre"  id="standard-basic" style={{'width': '100%'}} className="mb-3" label="Nombre" variant="standard" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <TextField onChange={leerDatos} value={descripcion} name="descripcion" id="standard-basic" style={{'width': '100%'}} className="mb-3" label="Descripcion" variant="standard" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <TextField type="number" value={precio} onChange={leerDatos} name="precio" id="standard-basic" style={{'width': '100%'}} className="mb-3" label="Precio Paquete" variant="standard" />
                            </div>
                            <div className="col-md-4">
                                <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                                    <InputLabel id="select-label">Armazones</InputLabel>
                                    <Select
                                    labelId="select-label"
                                    id="select-label"
                                    value={armazon}
                                    onChange={(value: any) => setArmazon(Number(value.target.value))}
                                    label="Contable"
                                    name='armazon'
                                    >
                                        <MenuItem disabled key={0} value={0}></MenuItem>
                                        {catArmazones && catArmazones.map((item: {id_articulo: number, nombre_articulo: string}) => (
                                            <MenuItem key={item.id_articulo} value={item.id_articulo}>{item.nombre_articulo}</MenuItem>
                                        ))}
                                        
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-4">
                                <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                                    <InputLabel id="select-label">Micas</InputLabel>
                                    <Select
                                    labelId="select-label"
                                    id="select-label"
                                    value={micas}
                                    onChange={(value) => setMicas(Number(value.target.value))}
                                    label="Micas"
                                    name='micas'
                                    >
                                         <MenuItem disabled key={0} value={0}></MenuItem>
                                        {catMicas && catMicas.map((item: {id_articulo: number, nombre_articulo: string}) => (
                                            <MenuItem key={item.id_articulo} value={item.id_articulo}>{item.nombre_articulo}</MenuItem>
                                        ))}
                                        
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-4">
                                <FormControl  variant="standard" sx={{ minWidth: '100%' }}>
                                    <InputLabel id="select-label">Accesorios</InputLabel>
                                    <Select
                                    labelId="select-label"
                                    id="select-label"
                                    value={accesorios}
                                    onChange={(value) => setAccesorios(Number(value.target.value))}
                                    label="Accesorios"
                                    name='accesorios'
                                    >
                                         <MenuItem disabled key={0} value={0}></MenuItem>
                                        {catAccesorios && catAccesorios.map((item: {id_articulo: number, nombre_articulo: string}) => (
                                            <MenuItem key={item.id_articulo} value={item.id_articulo}>{item.nombre_articulo}</MenuItem>
                                        ))}
                                        
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-4 mt-3">
                                <Button disabled={spAltaPaquete} variant="contained" style={{'width': '100%'}} onClick={guardarPaquete}>
                                    Guardar Paquete &nbsp;
                                    {spAltaPaquete && 
                                        <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </Button>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
                <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />

            </div>
        </>
    )

}

export default AltaPaquete