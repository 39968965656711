
const Convenios = () => {
    return (
        <div className="container-fluid mx-0 px-0 bg-azul">
            <p className="text-center titulo-convenios mx-0 px-0 py-2">CONVENIOS</p>
            <div className="container">
                <img src="/img/empresas.png" alt="" className="maxw" />
            </div>
        </div>
    )
}

export default Convenios