import { Link } from 'react-router-dom';

const Footer = () => {
    return(
        <div className="footer d-flex justify-content-center p-4 color-cuarto">
            <Link to={'https://www.facebook.com/opticamundopticosmexicali/?locale=es_LA'} target='new'>
                <i className="fa-brands fa-facebook fa-2xl m-2 shadow"></i>
            </Link>
            <Link to={'https://www.instagram.com/mundoopticos/?hl=es'} target='new'>
                <i className="fa-brands fa-instagram fa-2xl m-2"></i>
            </Link>
        </div>
    )
}

export default Footer