import * as React from 'react';
import { Button, Input } from "@mui/material"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import apiMo from '../../axios/axios';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { useLoginStore } from "../../store/zustand"

interface IArticulo{
    nombre: string,
    descripcion: string,
    id_codigo: number,
    contable: number,
    precio: number,
    id_categoria: number,
    fecha_alta: string,
    id_tipo_articulo: number,

}

interface ICatCodigo{
    id_codigo: number,
    codigo: string,
    descripcion: string,
    activo: number,
    precio: number
}

//CREAR APARTADO PARA MODIFICAR INFORMACION DE ARTICULO

const Inventario = () => {

    const { loginStore } = useLoginStore()

    const [nombre, setNombre] = React.useState('')
    const [descripcion, setDescripcion] = React.useState('')

    const [contable, setContable] = React.useState(2);
    const [categoria, setCategoria] = React.useState(0);
    const [catCategoria, setCatCategoria] = React.useState([]);
    const [codigo, setCodigo] = React.useState(0);
    const [catCodigo, setCatCodigo] = React.useState<ICatCodigo[]>([]);
    const [precio, setPrecio] = React.useState(0)
    const [tipoArticulo, setTipoArticulo] = React.useState(0);
    const [catTipoArticulo, setCatTipoArticulo] = React.useState([]);
    const [articulo, setArticulo] = React.useState<IArticulo>({nombre: '', descripcion: '', id_codigo: -1 , contable: -1, precio: 0, id_categoria: -1, fecha_alta: '', id_tipo_articulo: -1})

    const navigate = useNavigate()

    const [spAltaArticulo, setSpAltaArticulo] = React.useState(false)

    const agregarArticulo = async() => {

        let key: string = ''

        articulo.nombre = nombre
        articulo.descripcion = descripcion
        articulo.id_codigo = codigo
        articulo.contable = contable
        articulo.id_categoria = categoria
        articulo.id_tipo_articulo = tipoArticulo
        articulo.precio = precio

        if(!articulo.nombre) key = 'nombre'
        if(!articulo.descripcion) key = 'descripcion'
        if(articulo.id_codigo === 0) key = 'codigo'
        if(articulo.contable === 2) key = 'contable'
        if(articulo.id_categoria === 0) key = 'categoria'
        if(articulo.id_tipo_articulo === 0) key = 'tipo_articulo'
        if(articulo.precio === 0) key = 'precio'

        if(key){
            return toast.error(`El campo ${key} es obligatorio`, toast_config)
        }

        const cd = new Date()
        const scd = `${cd.getFullYear()}-${cd.getMonth() + 1}-${cd.getDate()} ${cd.getHours()}:${cd.getMinutes()}:${cd.getSeconds()}`

        articulo.fecha_alta = scd

        //PONER VALIDACION DE SI ARTICULO YA EXISTE

        try {

            Swal.fire({
                title: 'Estas seguro?',
                text: "Se dara de alta articulo!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, dar de alta!'
              }).then(async(result) => {
                if(result.isConfirmed){

                    setSpAltaArticulo(true)

                    const agregando = await apiMo.post('/alta-articulo',articulo,{
                        headers: {
                            'Authorization' : `Bearer ${loginStore.token}`,
                            'Accept': 'application/x-www-form-urlencoded',
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    })
                    setSpAltaArticulo(false)
                    Swal.fire({
                        icon: 'success',
                        title: 'Enhorabuena!',
                        text: agregando.data.mensaje
                    }).then(resul => {
                        navigate('/home')
                    })



                }
            }).catch((error) => {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar articulo, favor de volver a intentar!'
                    }
                }

                //mensaje de toastify
                toast.error(msg_error, toast_config)
                setSpAltaArticulo(false)
            });

        } catch (error : any) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.error){
                    msg_error = error.response.data.error
                }else{
                    msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                }
            }
            //mensaje de toastify
            toast.error(msg_error, toast_config)
            setSpAltaArticulo(false)
        }

    }

    /*const leerDatos = (e: any) => {
        if(e.target.name === 'contable'){
            setContable(e.target.value)
        }
        if(e.target.name === 'id_categoria'){
            setCategoria(e.target.value)
        }

        if(e.target.name === 'id_codigo'){

            setCodigo(e.target.value)
        }

        if(e.target.name === 'id_tipo_articulo'){
            setTipoArticulo(e.target.value)
        }

        if(e.target.name === 'nombre'){
            setNombre(e.target.value.toUpperCase())
        }

        if(e.target.name === 'descripcion'){
            setDescripcion(e.target.value.toUpperCase())
        }

        setArticulo({
            ...articulo,
            [e.target.name] : typeof e.target.value === 'string' ? e.target.value.toUpperCase() : e.target.value
        })
    }*/

    const catalogos = () => {

        const cargar = async() => {

            try {
                const cargar_catalogos = await apiMo.get('/catalogos-crear-articulo',{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setCatCodigo(cargar_catalogos.data.codigos)
                setCatCategoria(cargar_catalogos.data.categorias)
                setCatTipoArticulo(cargar_catalogos.data.tipo_articulo)

            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                        msg_error = 'Ocurrió un error al cargar catalogos, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

            

        }

        cargar()

    }

    React.useEffect(() => {
        catalogos()
    },[])

    return(
        <div className="col-md-8 animation-layout">
            <div className="card shadow-lg">
                <div className="card-body">
                    <h3>Alta de Articulos</h3>
                    <hr />
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Input onChange={(e) => setNombre(e.target.value.toUpperCase())} value={nombre} name='nombre' placeholder="Nombre" style={{'width': '50%'}} />
                        </div>

                        <div className="col-md-6">
                            <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                                <InputLabel id="labelc">Tipo de Articulo</InputLabel>
                                <Select
                                labelId="labelc"
                                id="labelc"
                                value={tipoArticulo}
                                onChange={(e) => setTipoArticulo(Number(e.target.value))}
                                label="Tipo de Articulo"
                                name='id_tipo_articulo'
                                >
                                    <MenuItem key={0} value={0} disabled>ESCOGER TIPO DE ARTICULO</MenuItem>
                                    {catTipoArticulo && catTipoArticulo.map((ta: {id_tipo_articulo: number, tipo_articulo: string, descripcion: string, activo: number}) => (
                                            <MenuItem key={ta.id_tipo_articulo} value={ta.id_tipo_articulo}>{ta.tipo_articulo}</MenuItem>
                                        ))
                                        
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        
                        <div className="col-md-6 mb-3">
                            <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                                <InputLabel id="labelc">Codigo</InputLabel>
                                <Select
                                labelId="labelc"
                                id="labelc"
                                value={codigo}
                                onChange={(e) => {
                                    setCodigo(Number(e.target.value))
                                    const codigo_id = catCodigo.filter(obj => obj.id_codigo === Number(e.target.value))

                                    setPrecio(codigo_id[0].precio)

                                }}
                                label="Codigo"
                                name='id_codigo'
                                >
                                    <MenuItem key={0} value={0} disabled>ESCOGER CODIGO</MenuItem>
                                    {catCodigo && catCodigo.map((codigo: {id_codigo: number, codigo: string, descripcion: string, activo: number}) => (
                                            <MenuItem key={codigo.id_codigo} value={codigo.id_codigo}>{codigo.codigo}</MenuItem>
                                        ))
                                        
                                    }
                                </Select>
                            </FormControl>
                            {/*<Input onChange={leerDatos} name='codigo' placeholder="Codigo" style={{'width': '100%'}} />*/}
                            {/*<Input type='file' onChange={leerDatos} name='codigo' placeholder="Codigo" style={{'width': '100%'}} />*/}
                        </div>
                        <div className='col-md-6 d-flex align-items-center'>
                            <Input onChange={(e) => setDescripcion(e.target.value.toUpperCase())} value={descripcion} name='descripcion' placeholder="Descripción" style={{'width': '100%'}} />
                        </div>
                        <div className="col-md-6 mb-3">
                            
                            <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                                <InputLabel id="select-label">Contable</InputLabel>
                                <Select
                                labelId="select-label"
                                id="select-label"
                                value={contable}
                                onChange={(e) => setContable(Number(e.target.value))}
                                label="Contable"
                                name='contable'
                                >
                                    <MenuItem value={2} disabled>ESCOGER OPCION</MenuItem>
                                    <MenuItem value={1}>Si</MenuItem>
                                    <MenuItem value={0}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-6 d-flex align-items-center  '>
                            <Input value={precio} disabled={true} name='precio' placeholder="Precio" style={{'width': '100%'}} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                                <InputLabel id="select-label">Categoria</InputLabel>
                                <Select
                                labelId="select-label"
                                id="select-label"
                                value={categoria}
                                onChange={(e) => setCategoria(Number(e.target.value))}
                                label="Categoria"
                                name='id_categoria'
                                >
                                    <MenuItem key={0} value={0} disabled>ESCOGER CATEGORIA</MenuItem>
                                    {catCategoria && catCategoria.map((categoria: {id_categoria: number, categoria: string, descripcion: string, activo: number}) => (
                                            <MenuItem key={categoria.id_categoria} value={categoria.id_categoria}>{categoria.categoria}</MenuItem>
                                        )) 
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                        <Button disabled={spAltaArticulo} variant="contained" onClick={agregarArticulo}>
                            Agregar &nbsp;
                            {spAltaArticulo && 
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                        </Button>
                    <hr />
                </div>
            </div>
            <ToastContainer position="bottom-center"
                transition={Flip}
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default Inventario