import { Outlet, useNavigate } from "react-router-dom"
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useLoginStore } from "../../store/zustand"

const Inactividad = () => {

    const {guardarAuth} = useLoginStore()
    const navigate = useNavigate()

    const resetAuth = () => {
        if(typeof localStorage !== 'undefined' && localStorage.getItem('token') !== null){
            guardarAuth({
                auth: false,
                token: '',
                jtoken: '',
            })
        
            localStorage.removeItem('token')

            navigate('/')

            Swal.fire({
                icon: 'info',
                title: 'Inactividad',
                text: 'Exceso de inactividad, vuelva a iniciar sesión!'
            })
        }
    }
    useEffect(() => {
        
            let inactividad:any = null;
        
            const resetearTiempo = () => {
                clearTimeout(inactividad);
                inactividad = setTimeout(resetAuth, 600000); //600000
            };
        
            const handleActivity = () => {
                resetearTiempo();
            };
        
            window.addEventListener('mousemove', handleActivity);
            window.addEventListener('keydown', handleActivity);
        
            resetearTiempo();
        
            return () => {
                
                clearTimeout(inactividad);
                window.removeEventListener('mousemove', handleActivity);
                window.removeEventListener('keydown', handleActivity);
            };
            
    }, []);

    return(
        <div>
            <Outlet />
        </div>
    )

}

export default Inactividad