
import { TextField, Button } from "@mui/material"
import { useState } from "react"
import apiMo from "../../axios/axios"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import { useLoginStore } from "../../store/zustand"

interface IConvenio{
    nombre: string,
    descripcion: string
}

const Convenios = () => {
    const { loginStore } = useLoginStore()
    const [convenio, setConvenio] = useState<IConvenio>({nombre: '', descripcion: ''})
    const [spGrabarConvenio, setSpGrabarConvenio] = useState(false)

    const guardarConvenio = () => {

        const guardar_convenio = async() => {

            try {
                setSpGrabarConvenio(true)
                const creacion = await apiMo.post('/agregar-convenio', convenio,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                toast.success(creacion.data.mensaje, toast_config)

                setSpGrabarConvenio(false)

            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al crear convenio, favor de volver a intentar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                setSpGrabarConvenio(false)
            }

        }

        guardar_convenio()

    }

    const leerDatos = (e: any) => {

        //falta lo de la imagen

        setConvenio({
            ...convenio,
            [e.target.name] : e.target.value
        })

    }


    return(
        <>
            <div className="col-md-12">
                <div className="card shadow">
                    <div className="card-body">
                        <h3>Alta Convenios</h3>
                        <hr />
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <TextField onChange={leerDatos} name="nombre" id="standard-basic" style={{'width': '100%'}} className="mb-3" label="Nombre" variant="standard" />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <TextField name="descripcion" onChange={leerDatos} id="standard-basic" style={{'width': '100%'}} className="mb-3" label="Descripcion" variant="standard" />
                                </div>
                                <div className="col-md-6">
                                    <Button disabled={spGrabarConvenio} variant="contained" onClick={guardarConvenio}>
                                        Guardar
                                        {spGrabarConvenio && 
                                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                    </Button>
                                </div>
                            </div>
                        <hr />
                    </div>
                </div>
                <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    )

}

export default Convenios