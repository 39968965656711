import Body from "../Body/Body"
import "./Home.style.css"

const Home = () => {
    return(
        <div>
            <Body />
        </div>
    )
}

export default Home