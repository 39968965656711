import { Button, Input } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { useLoginStore } from "../../store/zustand"
import { useState, useEffect } from "react"
import apiMo from "../../axios/axios"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";

import jwtDecode from "jwt-decode"

const Login = () => {


    const { guardarAuth, loginStore } = useLoginStore()
    const [spIniciarSesion, setSpIniciarSesion] = useState(false)

    const [ credenciales, guardarCredenciales] = useState({})
    const [inputUsuario, setInputUsr] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        if(loginStore.auth) return navigate('/home')
    }, [])

    const iniciarSesion = async(e: any) => {
        e.preventDefault()

        try {
            setSpIniciarSesion(true)

            const autenticacion = await apiMo.post('/iniciar-sesion',credenciales,
            {
                headers: {
                    'Accept': 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })

            const { token } = autenticacion.data
            //spIniciarSesion.current.setAttribute('hidden', 'hidden')
            localStorage.setItem('token', token)
            const usr: any = jwtDecode(token)

            //colocarlo token en el state de zustand
            loginStore.auth = true
            /*loginStore.usuario = usr.usuario
            loginStore.id_usuario = usr.id_usuario
            loginStore.id_rol = usr.id_rol
            loginStore.nombre_completo = usr.nombre_completo*/
            loginStore.token = token
            loginStore.jtoken = usr
            /*guardarAuth({
                auth: true,
                usuario: usr.usuario,
                id_usuario : usr.id_usuario,
                id_rol: usr.id_rol,
                nombre_completo : usr.nombre_completo,
                token
            })*/
            
            setSpIniciarSesion(false)
            //redireccionar
            navigate('/home')

        } catch (error: any) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.mensaje){
                    msg_error = error.response.data.error
                }else{
                    msg_error = 'Ocurrió un error autenticar usuario, favor de volver a cargar!'
                }
            }
            //mensaje de toastify
            toast.error(msg_error, toast_config)
            setSpIniciarSesion(false)
        }
        
       
    }

    const leerDatos = (e: any) => {
        if(e.target.name === 'usr'){
            const uppercaseValue = e.target.value.toUpperCase();
            e.target.value = uppercaseValue
            setInputUsr(uppercaseValue);
        }
        guardarCredenciales({
            ...credenciales,
            [e.target.name] : e.target.value
        })
    }

    return(
        <div className="bg-login d-flex align-items-center">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6">
                        <div className="card-transparent shadow-lg border-0 card-slide">
                            <div className="card-body m-0 p-0 shadow-lg">
                                <form className="row m-0 p-0 rounded shadow-lg" >
                                    <div className="col-md-4 bg-logologin rounded-start">
                                        <img src="/img/logowbg.png" alt="logo" className="maxw" width={'250px'}/>
                                    </div>
                                    <div className="col-md-8 bg-logologinsesion rounded-end">
                                        <Input value={inputUsuario} onChange={leerDatos} name='usr' className="my-2" placeholder="Usuario" style={{'width' : '100%'}} />
                                        <Input type="password" onChange={leerDatos} name='pass' className="my-2" placeholder="Contraseña" style={{'width' : '100%'}} />
                                        <Button disabled={spIniciarSesion} onClick={iniciarSesion} variant="outlined" className="my-2" style={{'width' : '100%'}}>
                                            Log In
                                            {spIniciarSesion && <div className="spinner-border spinner-border-sm" role="status" hidden id='spisLoad'>
                                                <span className="sr-only"></span>
                                            </div>}
                                        </Button>     
                                        
                                    </div>
                                </form>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                
            </div>
        </div>
    )
}

export default Login