
import { useNavigate } from "react-router-dom"
import HeaderColaboradores from "./HeaderColaboradores"
import { useLoginStore } from "../../store/zustand"
import { useEffect } from "react"

interface Componente{
    children: React.ReactNode
}

const LayoutColaborador = (props: Componente) => {

    const navigate = useNavigate()

    const { loginStore } = useLoginStore()

    useEffect(() => {
        if(!loginStore.auth){
            return navigate('/iniciar-sesion')
        }
    }, [])

    return(
        <div>
            <HeaderColaboradores /> 
            <div className="container pt-5">
                <div className="principalshow text-center">
                    <img src="/img/logo.jpg" width="750px" alt="logo" className="bg-imageprincipal" />
                </div>
                <div className="row d-flex justify-content-center">
                    {props.children}
                </div>
            </div>    
        </div>
    )
}

export default LayoutColaborador