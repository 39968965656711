
import { Fragment } from "react"
import "./Body.style.css"
import Principal from "./Principal"
import Header from "../Header/Header"
import VisionMision from "./VisionMision"
import Lentes from "./Lentes"
import Convenios from "./Convenios"
import Mapa from "./Mapa"
import Footer from "./Footer"

const Body = () => {
    return(
        <Fragment>
            <Header />
            <Principal />
            <Lentes />
            <Convenios />
            <VisionMision />
            <Mapa />
            <Footer />
        </Fragment>
    
    )
}

export default Body