
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'

const Mapa = () => {
    //const position: [number, number] = [51.505, -0.09]
        
    return(
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3359.8431952046685!2d-115.45255558843884!3d32.6369989910183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d7708aa9e426b9%3A0xd34c9c1b1c408cb7!2sMundo%20%C3%93pticos!5e0!3m2!1sen!2smx!4v1693281368784!5m2!1sen!2smx" width="100%" height="450"  loading="lazy"></iframe>
        {/*<MapContainer center={[32.63683,-115.44988]} zoom={17} scrollWheelZoom={false} style={{ width: "100%", height: "calc(50vh - 4rem)" }}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[32.63695,-115.44992]} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                <Popup>
                    <div className='text-center'>
                        <span className=''>Mundo Ópticos</span> 
                    </div>
                    
                    Calz Independencia 143-3, Insurgentes Oeste, 21280 Mexicali, B.C.
                </Popup>
            </Marker>
    </MapContainer>*/}
        </div>
    )
}

export default Mapa