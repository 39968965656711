import { Fragment } from "react"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Home from "../Home/Home"
import Login from "../Login/Login"
import LayoutColaborador from "../Colaboradores/LayoutColaborador"
import AltaArticulos from "../Colaboradores/AltaArticulos"
import Navegacion from "../Colaboradores/Navegacion"
import Articulos from "../Colaboradores/Articulos"
import AgregarInventario from "../Colaboradores/AgregarInventario"
import Convenios from "../Colaboradores/Convenios"
import AltaPaquete from "../Colaboradores/AltaPaquete"
import GenerarVenta from "../Colaboradores/GenerarVenta"
import AbonarVenta from "../Colaboradores/AbonarVenta"
import CancelarVenta from "../Colaboradores/CancelarVenta"
import CrearUsuario from "../Colaboradores/CrearUsuario"
import AltaProveedores from "../Colaboradores/AltaProveedores"
import Salidas from "../Colaboradores/Salidas"
import Reportes from "../Colaboradores/Reportes"
import Inactividad from "./Inactividad"

const Rutas = () => {

    return(
        <Router>
            <Fragment>  
                <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="/iniciar-sesion" element={<Login />}/>          
                    <Route path="/home" element={<LayoutColaborador>
                                                        <Navegacion />
                                                </LayoutColaborador>}
                    />
                    <Route path="/alta-articulos" element={<LayoutColaborador>
                                                            <AltaArticulos />
                                                        </LayoutColaborador>} 
                    /> 
                    <Route path="/articulos" element={<LayoutColaborador>
                                                            <Articulos />
                                                        </LayoutColaborador>} 
                    />
                    <Route path="/agregar-inventario" element={<LayoutColaborador>
                                                                <AgregarInventario />
                                                            </LayoutColaborador>} 
                    />
                    <Route path="/agregar-convenio" element={<LayoutColaborador>
                                                                <Convenios />
                                                            </LayoutColaborador>} 
                    />    
                    <Route path="/alta-paquetes" element={<LayoutColaborador>
                                                                <AltaPaquete />
                                                            </LayoutColaborador>} 
                    />  
                    <Route path="/generar-venta" element={<LayoutColaborador>
                                                                <GenerarVenta />
                                                            </LayoutColaborador>} 
                    />
                    <Route path="/abonar-venta" element={<LayoutColaborador>
                                                                <AbonarVenta />
                                                            </LayoutColaborador>} 
                    /> 
                    <Route path="/cancelar-venta" element={<LayoutColaborador>
                                                                <CancelarVenta />
                                                            </LayoutColaborador>} 
                    /> 
                    <Route path="/crear-usuario" element={<LayoutColaborador>
                                                                <CrearUsuario />
                                                            </LayoutColaborador>} 
                    />
                    <Route path="/alta-proveedor" element={<LayoutColaborador>
                                                                <AltaProveedores />
                                                            </LayoutColaborador>} 
                    />
                    <Route path="/salida-dinero" element={<LayoutColaborador>
                                                                <Salidas />
                                                            </LayoutColaborador>} 
                    /> 
                    <Route path="/generar-reporte" element={<LayoutColaborador>
                                                                <Reportes />
                                                            </LayoutColaborador>} 
                    /> 
                </Routes>
                <Inactividad />
                      
            </Fragment>
        </Router>
    )
    


}

export default Rutas