import { Box, Button, Modal, Table, TableContainer, TableHead, TableBody, TableRow, TextField, Paper } from "@mui/material"
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useEffect, useState } from "react"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import apiMo from "../../axios/axios";
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../store/zustand"

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

interface iVenta{
    id_venta: number,
    folio_venta: string,
    cliente: string,
    estatus_venta: string,
    id_estatus_venta: number,
    tipo_venta: string,
    convenio: string,
    total_venta: number,
    cantidad_pagada: number
}

interface iPagosVenta{
    id_pago: number,
    id_venta: number,
    monto: number,
    activo: string,
    fecha_pago: string,
    id_usr_registro: number
    //falta el nombre de quien lo registro
}

interface iPartidasVenta{
    id_venta: number,
    nombre_partida: string,
    cantidad_partida: number,
    total_partida: number,
    descuento_partida: number,
    id_partida: number
}

const AbonarVenta = () => {

    const [ventas, setVentas] = useState<iVenta[]>([])
    const [modalPagos, setModalPagos] = useState(false)
    const [pagosVenta, setPagosVenta] = useState<iPagosVenta[]>([])
    const [abono, setAbono] = useState(0)
    const [fechaAbono, setFechaAbono] = useState('')
    const [modalPartidas, setModalPartidas] = useState(false)
    const [partidasVenta, setPartidasVenta] = useState<iPartidasVenta[]>([])

    const navigate = useNavigate()
    const [spCancelarPago, setSpCancelarPago] = useState(false)
    const [spAgregarPagoVenta, setSpAgregarPagoVenta] = useState(false)
    const [ventaPagos, setVentaPagos] = useState(0)

    const { loginStore } = useLoginStore()


    const leerCatalogos = () => {

        const catalogos = async() => {

            try {

                const cargando = await apiMo.get('/leer-catalogos-abonoventa',{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setVentas(cargando.data.cat_ventas)

            
            } catch (error:any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

        }

        catalogos()

    }  
    
    const coincidenciaVentas = (e: any) => {

        if(e.target.value.length <= 3) return 

        const ventas_coincidencia = async() => {

            try {

                const cliente = btoa(e.target.value.toUpperCase())

                const buscar = await apiMo.get(`/coincidencia-ventas/${cliente}`,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                if(buscar.data.length <= 0) return toast.info('No se encontraron coincidencias!', toast_config)

                setVentas(buscar.data)
                
            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

        }

        ventas_coincidencia()
    }

    useEffect(() => {
        leerCatalogos()
    }, [])

    const verPagos = (e:any, venta:number) => {

        const buscarPagos = async() => {

            try {
                
                const idb64 = btoa(venta.toString())
                setVentaPagos(venta)
                const buscando = await apiMo.get(`/buscar-pagos-venta/${idb64}`,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setPagosVenta(buscando.data)
                setModalPagos(true)

            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

        }

        buscarPagos()

    } 

    const verPartidasVenta = (e:any, venta:number) => {
        
        const partidas_venta = async() => {

            try {   
                const b64venta = btoa(venta.toString())
                const partidas = await apiMo.get(`/buscar-partidas-venta/${b64venta}`,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setPartidasVenta(partidas.data)
                setModalPartidas(true)

            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al obtener partidas, favor de volver a intentar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

        }

        partidas_venta()


    }

    const agregarPagoVenta = () => {

        if(abono <= 0) return toast.warning('Para abonor es necesario agregar una cantidad!', toast_config)

        if(!fechaAbono) return toast.warning('Para poder agregar un abono es necesario escoger una fecha!', toast_config)

        const agregarPago = async() => {

            try {
                setModalPagos(false)
                Swal.fire({
                    title: 'Estas seguro?',
                    text: "Se abonara el pago a esta venta!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, abonar!'
                  }).then(async(result) => {
                    if (result.isConfirmed) {
                        setSpAgregarPagoVenta(true)

                        const abono_venta = {
                            id_venta: ventaPagos,
                            monto: abono,
                            fecha_pago: fechaAbono,
                            id_usr_registro: 1, //falta agregar usuario logueado
                        }
        
                        const agregando = await apiMo.post('/abonar-pago-venta', abono_venta,{
                            headers: {
                                'Authorization' : `Bearer ${loginStore.token}`,
                                'Accept': 'application/x-www-form-urlencoded',
                                'Content-Type': 'application/x-www-form-urlencoded',
                            }
                        })

                        leerCatalogos()
                        setSpAgregarPagoVenta(false)


                        Swal.fire({
                            icon: 'success',
                            title: 'Enhorabuena!',
                            text: agregando.data.mensaje
                        })
                        .then((result) => {
                            navigate('/abonar-venta')
                        })

                    }
                }).catch((error) => {
                    let msg_error = ''
                    if(error.response){
                        if(error.response.data.error){
                            msg_error = error.response.data.error
                        }else{
                                msg_error = 'Ocurrió un error al agregar pago, favor de volver a intentar!'
                        }
                    }

                    //mensaje de toastify
                    toast.error(msg_error, toast_config)
                    setSpAgregarPagoVenta(false)
                    
                });

            } catch (error : any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar pago, favor de volver a intentar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                setSpAgregarPagoVenta(false)
            }

        }

        agregarPago()

    }

    const cancelarPago = (e: any, pago: number) => {

        const cancelar_pago = async() => {

            try {

                Swal.fire({
                    title: 'Estas seguro?',
                    text: "Este pago se va a cancelar!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, cancelar!'
                  }).then(async(result) => {
                    if (result.isConfirmed) {
                        setSpCancelarPago(true)

                        const b64venta = btoa(pago.toString())

                        const cancelando = await apiMo.put(`/cancelar-pago/${b64venta}`,{
                            headers: {
                                'Authorization' : `Bearer ${loginStore.token}`,
                                'Accept': 'application/x-www-form-urlencoded',
                                'Content-Type': 'application/x-www-form-urlencoded',
                            }
                        })

                        setSpCancelarPago(false)

                        Swal.fire({
                            icon: 'success',
                            title: 'Enhorabuena!',
                            text: cancelando.data.mensaje
                        })
                        .then((result) => {
                            navigate('/abonar-venta')
                        })


                    }
                  }).catch((error) => {
                    let msg_error = ''
                    if(error.response){
                        if(error.response.data.error){
                            msg_error = error.response.data.error
                        }else{
                                msg_error = 'Ocurrió un error al cancelar pago, favor de volver a intentar!'
                        }
                    }

                    //mensaje de toastify
                    toast.error(msg_error, toast_config)
                    setSpCancelarPago(false)
                    
                });
                
            } catch (error :any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al cancelar pago, favor de volver a intentar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                setSpCancelarPago(false)
            }

        }

        cancelar_pago()

    }

    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card shadow-lg">
                            <div className="card-body m-3">
                                <div className="row">
                                    <TextField style={{'width': '25%'}} onChange={coincidenciaVentas} className="my-2" variant="standard" label="Buscar Cliente"/>
                                    <hr />
                                    <TableContainer component={Paper} className="my-2">
                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">Folio Venta</StyledTableCell>
                                                    <StyledTableCell align="center">Cliente</StyledTableCell>
                                                    <StyledTableCell align="center">Estatus</StyledTableCell>
                                                    <StyledTableCell align="center">Tipo Venta</StyledTableCell>
                                                    <StyledTableCell align="center">Convenio</StyledTableCell>
                                                    <StyledTableCell align="center">Total Venta</StyledTableCell>
                                                    <StyledTableCell align="center">Pagado</StyledTableCell>
                                                    <StyledTableCell align="center">Partidas</StyledTableCell>
                                                    <StyledTableCell align="center">Pagos</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { ventas && ventas.map((venta) => (
                                                    <StyledTableRow key={'A' + venta.id_venta} >
                                                        <StyledTableCell component="th" scope="row" className="text-center">
                                                            {venta.folio_venta}
                                                        </StyledTableCell>
                                                        <StyledTableCell  className="text-center">{venta.cliente}</StyledTableCell>
                                                        <StyledTableCell  className="text-center">{venta.estatus_venta}</StyledTableCell>
                                                        <StyledTableCell  className="text-center">{venta.tipo_venta}</StyledTableCell>
                                                        <StyledTableCell  className="text-center">{venta.convenio}</StyledTableCell>
                                                        <StyledTableCell  className="text-center">${venta.total_venta}</StyledTableCell>
                                                        <StyledTableCell  className="text-center">
                                                            {venta.cantidad_pagada >= venta.total_venta ? 'PAGADO' : 'PENDIENTE'}
                                                        </StyledTableCell>
                                                        <StyledTableCell  className="text-center">
                                                            <Button variant="outlined" onClick={(e) => verPartidasVenta(e, venta.id_venta)}>Ver</Button>
                                                        </StyledTableCell>
                                                        <StyledTableCell  className="text-center">
                                                            <Button disabled={venta.id_estatus_venta === 3 ? true : false} variant="outlined" onClick={(e) => verPagos(e, venta.id_venta)}>Ver</Button>
                                                        </StyledTableCell>
                                                            
                                                    </StyledTableRow>
                                                ))}
                                                   
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                        <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />

            </div>

           {/**MODAL PARTIDAS DE VENTA */}

           <Modal
                open={modalPartidas}
                onClose={() => setModalPartidas(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
           >
                <Box sx={style}>
                    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">#</StyledTableCell>
                                    <StyledTableCell align="center">Partida</StyledTableCell>
                                    <StyledTableCell align="center">Cantidad</StyledTableCell>
                                    <StyledTableCell align="center">Total</StyledTableCell>
                                    <StyledTableCell align="center">Descuento</StyledTableCell>                  
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    
                                {partidasVenta && partidasVenta.length > 0 ? partidasVenta.map((partida, i) => (
                                    <StyledTableRow key={partida.id_partida} >
                                        <StyledTableCell component="th" scope="row" className="text-center">
                                            {i + 1}
                                        </StyledTableCell>
                                        <StyledTableCell className="text-center">{partida.nombre_partida}</StyledTableCell>
                                        <StyledTableCell className="text-center">{partida.cantidad_partida}</StyledTableCell>
                                        <StyledTableCell className="text-center">${partida.total_partida}</StyledTableCell>
                                        <StyledTableCell className="text-center">{partida.descuento_partida}%</StyledTableCell>
                                    </StyledTableRow>
                                        )):
                                        <StyledTableRow key={'no encontrado'} >
                                            <StyledTableCell colSpan={4} align="center" className="text-danger fw-bold text-center">No se encontraron partidas.</StyledTableCell>

                                        </StyledTableRow> 
                                        }
                            </TableBody>
                        </Table>                                     
                    </TableContainer>

                </Box>
           </Modal>
            
            {/**MODAL PAGOS DE VENTA */}
            <Modal
                open={modalPagos}
                onClose={() => setModalPagos(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {/**SI LA VENTA YA SE ENCUENTRA PAGADO, NO PODER ABONAR PODER VER COMO HACER ESO*/}
                    <div className="row">
                        <div className="col-md-4  d-flex justify-content-center mb-3">
                           <TextField type="number" style={{'width': '75%'}} value={abono} onChange={(e) => setAbono(Number(e.target.value))} variant="standard" label="Abono"></TextField>                         
                        </div>
                        <div className="col-md-4   d-flex justify-content-center mb-3">
                            <input type="date" style={{'width': '75%'}} className="form-control" value={fechaAbono} onChange={(e) => setFechaAbono(e.target.value)} />
                        </div>
                        <div className="col-md-4  d-flex justify-content-center mb-3">
                            <Button size="small" style={{'width': '75%'}} disabled={spAgregarPagoVenta} variant="contained" onClick={agregarPagoVenta} >
                                Agregar Pago &nbsp;
                                {spAgregarPagoVenta && 
                                    <div className="spinner-border text-light spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                            </Button>                      
                        </div>
                        <div className="col-md-12">
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Fecha Pago</StyledTableCell>
                                    <StyledTableCell align="center">Monto</StyledTableCell>
                                    <StyledTableCell align="center">Activo</StyledTableCell>
                                    <StyledTableCell align="center">Usuario Registro</StyledTableCell>
                                    <StyledTableCell align="center">Cancelar Pago</StyledTableCell>                   
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    
                                {pagosVenta && pagosVenta.length > 0 ? pagosVenta.map((pagos) => (
                                    <StyledTableRow key={pagos.id_pago}>
                                        <StyledTableCell component="th" scope="row" className="text-center">
                                            {pagos.fecha_pago}
                                        </StyledTableCell >
                                        <StyledTableCell className="text-center">${pagos.monto}</StyledTableCell>
                                        <StyledTableCell className="text-center" style={pagos.activo === '1' ? {'color' : 'green'}: {'color': 'red'}}>{pagos.activo === '1' ? 'Activo' : 'Cancelado'}</StyledTableCell>
                                        <StyledTableCell className="text-center">{pagos.id_usr_registro}</StyledTableCell>
                                        <StyledTableCell className="text-center">
                                            <Button variant="outlined" disabled={spCancelarPago} onClick={(e) => cancelarPago(e, pagos.id_pago)} color="error">
                                                Cancelar &nbsp;
                                                {spCancelarPago && 
                                                    <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                        )):
                                        <StyledTableRow key={'no encontrado'} >
                                            <StyledTableCell colSpan={5} align="center" className="text-danger fw-bold text-center">No se encontraron pagos.</StyledTableCell>

                                        </StyledTableRow> 
                                        }
                            </TableBody>
                        </Table>
                    </TableContainer>               
                        </div>
                    </div>
                </Box>
            </Modal>    
                

        </>
    )

}

export default AbonarVenta