
import { Button, Table, TableContainer, TableHead, TableBody, TableRow, TextField, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import apiMo from "../../axios/axios"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../store/zustand"


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

interface iVenta{
    id_venta: number,
    folio_venta: string,
    cliente: string,
    estatus_venta: string,
    id_estatus_venta: number,
    tipo_venta: string,
    convenio: string,
    total_venta: number,
    pagado: number
}


const CancelarVenta = () => {

    const { loginStore } = useLoginStore()

    const [ventas, setVentas] = useState<iVenta[]>([])

    const navigate = useNavigate()

    const [spCancelarVenta, setSpCancelarVenta] = useState(false)


    const leerCatalogos = () => {

        const catalogos = async() => {

            try {

                const cargando = await apiMo.get('/leer-catalogos-cancelarventa',{
                    headers: {
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setVentas(cargando.data.cat_ventas)


            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

            


        }

        catalogos()

    }

    const coincidenciaVentas = (e: any) => {

        if(e.target.value.length <= 3) return 

        const ventas_coincidencia = async() => {

            try {

                const cliente = btoa(e.target.value.toUpperCase())

                const buscar = await apiMo.get(`/coincidencia-ventas/${cliente}`,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                if(buscar.data.length <= 0) return toast.info('No se encontraron coincidencias!', toast_config)

                setVentas(buscar.data)
                
            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

        }

        ventas_coincidencia()
    }

    useEffect(() => {
        leerCatalogos()
    }, [])

    const cancelarVenta = (e: any, venta: number) => {

        const cancelar_venta = async() => {

            try {
                
                Swal.fire({
                    title: 'Estas seguro?',
                    text: "Esta venta se va a cancelar!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, cancelar!'
                  }).then(async(result) => {
                    if (result.isConfirmed) {
                        setSpCancelarVenta(true)
                        const b64venta = btoa(venta.toString())

                        const cancelando = await apiMo.put(`/cancelar-venta/${b64venta}`,{
                            headers: {
                                'Accept': 'application/x-www-form-urlencoded',
                                'Content-Type': 'application/x-www-form-urlencoded',
                            }
                        })

                        setSpCancelarVenta(false)

                        Swal.fire({
                            icon: 'success',
                            title: 'Enhorabuena!',
                            text: cancelando.data.mensaje
                        })
                        .then((result) => {
                            navigate('/cancelar-venta')
                        })


                    }
                  }).catch((error) => {
                    let msg_error = ''
                    if(error.response){
                        if(error.response.data.error){
                            msg_error = error.response.data.error
                        }else{
                                msg_error = 'Ocurrió un error al cancelar venta, favor de volver a intentar!'
                        }
                    }
    
                    //mensaje de toastify
                    toast.error(msg_error, toast_config)
                    setSpCancelarVenta(false)
                });


            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                setSpCancelarVenta(false)
            }

        }

        cancelar_venta()


    } 

    return(
        <>
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">

                            <TextField onChange={coincidenciaVentas} className="my-2" variant="standard" label="Buscar Cliente"/>
                                    <hr />
                                    <TableContainer component={Paper} className="my-2">
                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="right">Folio Venta</StyledTableCell>
                                                    <StyledTableCell align="right">Cliente</StyledTableCell>
                                                    <StyledTableCell align="right">Estatus</StyledTableCell>
                                                    <StyledTableCell align="right">Tipo Venta</StyledTableCell>
                                                    <StyledTableCell align="right">Cancelar Venta</StyledTableCell>
                                                    
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { ventas && ventas.map((venta) => (
                                                    <StyledTableRow key={'A' + venta.id_venta} >
                                                        <StyledTableCell component="th" scope="row" className="text-center">
                                                            {venta.folio_venta}
                                                        </StyledTableCell>
                                                        <StyledTableCell  className="text-center">{venta.cliente}</StyledTableCell>
                                                        <StyledTableCell  className="text-center">{venta.estatus_venta}</StyledTableCell>
                                                        <StyledTableCell  className="text-center">{venta.tipo_venta}</StyledTableCell>
                                                        <StyledTableCell  className="text-center">
                                                            <Button disabled={spCancelarVenta} variant="outlined" color="error" onClick={(e) => cancelarVenta(e, venta.id_venta)}>
                                                                Cancelar &nbsp;
                                                                {spCancelarVenta && 
                                                                    <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                }
                                                            </Button>    
                                                        </StyledTableCell>
                                                            
                                                    </StyledTableRow>
                                                ))}
                                                   
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    )

}

export default CancelarVenta