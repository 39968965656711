import { TextField, Button } from "@mui/material"
import { useState } from "react"
import apiMo from "../../axios/axios"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import { useLoginStore } from "../../store/zustand";
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom";

const AltaProveedores = () => {

    const { loginStore } = useLoginStore()

    const navigate = useNavigate()

    const [proveedor, setProveedor] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [spProveedor, setSpProveedor] = useState(false)

    const guardarProveedor = () => {

        const guardar_proveedor = async() => {

            try {
                setSpProveedor(true)
                if(!proveedor) return toast.error('Es necesario el nombre del proveedor', toast_config)
                if(!descripcion) return toast.error('Es necesario la descripcion del proveedor', toast_config)

                const guardar = {
                    proveedor, descripcion
                }

                const guardando = await apiMo.post('/guardar-proveedor', guardar, {
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setSpProveedor(false)
                Swal.fire({
                    icon: 'success',
                    title: 'Enhorabuena!',
                    text: guardando.data.mensaje
                }).then(result => {
                    navigate('/home')
                })

            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                        msg_error = 'Ocurrió un error al guardar proveedor, favor de volver a intentar!'
                    }
                }

                setSpProveedor(false)
                toast.error(msg_error, toast_config)

            }

        }

        guardar_proveedor()


    }

    return(
        <>
            <div className="cointainer">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <h3>Alta de Proveedores</h3>
                                <hr />
                                <div className="row">
                                    <TextField label='Nombre Proveedor' variant="standard" value={proveedor} className="mx-2 mb-2" onChange={(e) => setProveedor(e.target.value.toUpperCase())} style={{'width' : '45%'}} />
                                    <TextField label='Descripcion Proveedor' variant="standard" value={descripcion} className="mx-2 mb-2" onChange={(e) => setDescripcion(e.target.value.toUpperCase())} style={{'width' : '45%'}} />
                                    <br />
                                    <Button disabled={spProveedor} variant="contained" onClick={guardarProveedor}>
                                        Guardar
                                        {spProveedor && 
                                        <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                    </Button>
                                </div>
                                <hr />
                            </div>
                             
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />

            </div>
        </>
    )

}

export default AltaProveedores