import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import apiMo from "../../axios/axios"
import { useLoginStore } from "../../store/zustand"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";

interface iModulos{
    usuario: string,
    id_modulo: number,
    ruta_modulo: string,
    img_mod: string,
    btn_text: string
}

const Navegacion = () => {

    const [modulos, setModulos] = useState<iModulos[]>([])

    const { loginStore } = useLoginStore()

    const modulosUsuario = () => {
        if(!loginStore.auth) return

        const cargar_modulos = async() => {

            try {
                
                const b64idusr = btoa(loginStore.jtoken.usuario)

                const cargando = await apiMo.get(`/modulos-usuario/${b64idusr}`,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setModulos(cargando.data)

            } catch (error:any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                        msg_error = 'Ocurrió un error al cargar accesos de usuario, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

            


        }

        cargar_modulos()

    }


    useEffect(() => {
        modulosUsuario()
    }, [])

    return(
        <>

        {modulos && modulos.map(modulo => (
            <div key={modulo.id_modulo} className="col-md-3 d-flex justify-content-center animation-layout">
                <div className="card-transparent">
                    <div className="card-body text-center">
                        <Link to={modulo.ruta_modulo}>
                            <img src={modulo.img_mod} style={{'cursor': 'pointer'}} alt="" width={'150px'} /><br/>
                        </Link>
                        <Link to={modulo.ruta_modulo}>
                            <Button variant="contained">{modulo.btn_text}</Button>
                        </Link>
                    </div>
                </div>
            </div>
        ))}

            {/*<div className="col-md-3 d-flex justify-content-center animation-layout">
                <div className="card-transparent">
                    <div className="card-body text-center">
                        <Link to={"/alta-articulos"}>
                            <img src="/img/agregar.png" style={{'cursor': 'pointer'}} alt="" width={'150px'} /><br/>
                        </Link>
                        <Link to={"/alta-articulos"}>
                            <Button variant="contained">ALTA ARTICULOS</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center animation-layout">
                <div className="card-transparent">
                    <div className="card-body text-center">
                        <Link to={"/articulos"}>
                            <img src="/img/articulos.png" style={{'cursor': 'pointer'}} alt="" width={'150px'} /><br/>
                        </Link>
                        <Link to={"/articulos"}>
                            <Button variant="contained">ARTICULOS</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center animation-layout">
                <div className="card-transparent">
                    <div className="card-body text-center">
                        <Link to={"/agregar-inventario"}>
                            <img src="/img/inventario.png" style={{'cursor': 'pointer'}} alt="" width={'150px'} /><br/>
                        </Link>
                        <Link to={"/agregar-inventario"}>
                            <Button variant="contained">AGREGAR INVENTARIO</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center animation-layout">
                <div className="card-transparent">
                    <div className="card-body text-center">
                        <Link to={"/agregar-convenio"}>
                            <img src="/img/convenio.png" style={{'cursor': 'pointer'}} alt="" width={'150px'} /><br/>
                        </Link>
                        <Link to={"/agregar-convenio"}>
                            <Button variant="contained">ALTA CONVENIOS</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center animation-layout">
                <div className="card-transparent">
                    <div className="card-body text-center">
                        <Link to={"/alta-paquetes"}>
                            <img src="/img/paquetes.png" style={{'cursor': 'pointer'}} alt="" width={'150px'} /><br/>
                        </Link>
                        <Link to={"/alta-paquetes"}>
                            <Button variant="contained">ALTA PAQUETES</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center animation-layout">
                <div className="card-transparent">
                    <div className="card-body text-center">
                        <Link to={"/generar-venta"}>
                            <img src="/img/generar-venta.png" style={{'cursor': 'pointer'}} alt="" width={'150px'} /><br/>
                        </Link>
                        <Link to={"/generar-venta"}>
                            <Button variant="contained">GENERAR VENTA</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center animation-layout">
                <div className="card-transparent">
                    <div className="card-body text-center">
                        <Link to={"/abonar-venta"}>
                            <img src="/img/abonar-venta.png" style={{'cursor': 'pointer'}} alt="" width={'150px'} /><br/>
                        </Link>
                        <Link to={"/abonar-venta"}>
                            <Button variant="contained">ABONAR VENTA</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center animation-layout">
                <div className="card-transparent">
                    <div className="card-body text-center">
                        <Link to={"/cancelar-venta"}>
                            <img src="/img/cancelar-venta.png" style={{'cursor': 'pointer'}} alt="" width={'150px'} /><br/>
                        </Link>
                        <Link to={"/cancelar-venta"}>
                            <Button variant="contained">CANCELAR VENTA</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center animation-layout">
                <div className="card-transparent">
                    <div className="card-body text-center">
                        <Link to={"/crear-usuario"}>
                            <img src="/img/crear-usuario.png" style={{'cursor': 'pointer'}} alt="" width={'150px'} /><br/>
                        </Link>
                        <Link to={"/crear-usuario"}>
                            <Button variant="contained">CREAR USUARIO</Button>
                        </Link>
                    </div>
                </div>
            </div>*/}

            <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
        </>   
        

        

    )
}

export default Navegacion

