import { InputLabel,FormControl, MenuItem, Select, TextField, Button } from "@mui/material"
import { useEffect, useState } from "react"
import apiMo from "../../axios/axios"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import * as React from 'react';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../store/zustand"

//AGREGAR OPCION DE ENTRADA O SALIDA DE INVENTARIO

interface IArticuloContable{
    id_articulo: number;
    id_categoria_articulo: number;
    categoria_articulo: string;
    nombre_articulo: string;
    descripcion_articulo: string;
    precio_articulo: number;
    fecha_alta_articulo: string;
    articulo_contable: string;
    img_articulo: string;
    id_codigo: number;
    codigo_articulo: string;
    cantidad_inventario: number;
}

const AgregarInventario = () => {

    const [articulos, setArticulos] = useState<Array<IArticuloContable>>([])
    const [articulo, setArticulo] = useState('')
    const [infoArticulo, setInfoArticulo] = useState(false)
    const [tNombre, setTnombre] = useState('')
    const [tDescripcion, setTdescripcion] = useState('')
    const [tCantidadActual, setTcantidadActual] = useState('')
    const [tCantidad, setTcantidad] = useState(0)
    const [articuloInventario, setArticuloInventario] = useState(0)

    const [spAgregarInventario, setSpAgregarInventario] = useState(false)

    const navigate = useNavigate()

    const { loginStore } = useLoginStore()


    const articulosContables = async() => {

        try {
            const articulos_contables = await apiMo.get('/obtener-articulos-contables',{
                headers:{
                    'Authorization' : `Bearer ${loginStore.token}`,
                    'Accept': 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            setArticulos(articulos_contables.data)
        } catch (error: any) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.error){
                    msg_error = error.response.data.error
                }else{
                    msg_error = 'Ocurrió un error al obtener articulo contables, favor de volver a cargar!'
                }
            }
            //mensaje de toastify
            toast.error(msg_error, toast_config)
        }


    }

    useEffect(() => {
        articulosContables()
    },[])

    const handleChange = (e: any) => {
        setArticulo(e.target.value)

        const articulo: any = articulos[e.target.value.toString().split('-')[1]]

        setArticuloInventario(articulo.id_articulo)

        setTnombre(articulo.nombre_articulo)
        setTdescripcion(articulo.descripcion_articulo)
        setTcantidadActual(articulo.cantidad_inventario.toString())  

        setInfoArticulo(true)
    }

    const guardarCantidad = () => {

        const guardar_cantidad = async() => {

            if(!tCantidad || tCantidad < 0) return toast.error('Es necesario ingresar una cantidad!')

            try {

                Swal.fire({
                    title: 'Estas seguro?',
                    text: "Se agregara inventario a articulo!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, agregar!'
                  }).then(async(result) => {
                    if(result.isConfirmed){
                        setSpAgregarInventario(true)
                        const arti_cant = {
                            cantidad: tCantidad,
                            usr : 1
                        }
        
                        const guardar_inventario = await apiMo.put(`/agregar-inventario/${articuloInventario}`,arti_cant,{
                            headers: {
                                'Authorization' : `Bearer ${loginStore.token}`,
                                'Accept': 'application/x-www-form-urlencoded',
                                'Content-Type': 'application/x-www-form-urlencoded',
                            }
                        })

                        setSpAgregarInventario(false)

                        Swal.fire({
                            icon: 'success',
                            title: 'Enhorabuena!',
                            text: guardar_inventario.data.mensaje
                        }).then(result => {
                            navigate('/agregar-inventario')
                        })
                    }
                }).catch((error) => {
                    let msg_error = ''
                    if(error.response){
                        if(error.response.data.error){
                            msg_error = error.response.data.error
                        }else{
                                msg_error = 'Ocurrió un error al agregar cantidad, favor de volver a intentar!'
                        }
                    }

                    //mensaje de toastify
                    toast.error(msg_error, toast_config)
                    setSpAgregarInventario(false)
                    
                });


            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar inventario de articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                setSpAgregarInventario(false)
            }

           

        }

        guardar_cantidad()

    }

    const handleCantidad = (e: any) => {
        setTcantidad(e.target.value)
    }


    return(
        <>
            <div className="col-md-10 animation-layout">
                <div className="card shadow">
                    <div className="card-body">
                        
                        <h3>Agregar Inventario</h3>
                        <hr />
                        <div className="row">
                            <div className="col-md-5 mb-3">
                                <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                                    <InputLabel id="articulos-label">Articulos Contables</InputLabel>
                                    <Select
                                        labelId="articulos-label"
                                        id="articulos-label"
                                        value={articulo}
                                        onChange={handleChange}
                                        label="Articulos Contables"
                                    >
                                        {articulos && articulos.map((art,i) => (
                                            <MenuItem value={`${art.id_articulo}-${i}`} key={art.id_articulo}>{art.nombre_articulo}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="col-md-7"></div>

                            {infoArticulo && 
                                <>
                                <div className="col-md-4 ">
                                    <TextField value={tNombre} disabled id="standard-basic" style={{'width': '100%'}} className="mb-3" label="Nombre" variant="standard" />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <TextField value={tDescripcion} disabled id="standard-basic" style={{'width': '100%'}} label="Descripcion" variant="standard" />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <TextField value={tCantidadActual} disabled id="standard-basic" style={{'width': '100%'}} label="Cantidad Actual" variant="standard" />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <TextField type="number" onChange={handleCantidad} value={tCantidad} id="standard-basic" style={{'width': '100%'}} label="Agregar Cantidad" variant="standard" />
                                </div>
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <Button disabled={spAgregarInventario} variant="contained" style={{'width' : '100%'}} onClick={guardarCantidad}>
                                        Guardar &nbsp;
                                        {spAgregarInventario && 
                                            <div className="spinner-border text-light spinner-border-sm" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        }
                                    </Button>
                                </div>
                                </>
                            }
                            

                        </div>
                        <hr />
                    </div>
                </div>
                <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    )
}

export default AgregarInventario