import { useEffect, useState } from "react"
import apiMo from "../../axios/axios"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import { useLoginStore } from "../../store/zustand";
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";


interface iProveedores{
    id_proveedor: number,
    proveedor: string,
    descripcion: string
}

interface iTipoPagos{
    id_tipo_pago : number,
    tipo_pago: string,
    descripcion : string
}

const Salidas = () => {

    const {loginStore} = useLoginStore()

    const [catProveedores, setCatProveedores] = useState<iProveedores[]>([])
    const [proveedor, setProveedor] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [total, setTotal] = useState(0)
    const [cantidad, setCantidad] = useState(0)
    const [fechaSalida, setFechaSalida] = useState('')
    const [spGuardarSalida, setSpGuardarSalida] = useState(false)
    const [catTipoPago, setCatTipoPago] = useState<iTipoPagos[]>([])
    const [tipoPago, setTipoPago] = useState('')
    const navigate = useNavigate()


    const cargarCatalogos = () => {

        const cargar_catalogos = async() => {

            try {
                
                const cargando = await apiMo.get('/leer-catalogos-salidas',{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setCatProveedores(cargando.data.proveedores)
                setCatTipoPago(cargando.data.tipo_pagos)

            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                        msg_error = 'Ocurrió un error al cargar catalogos, favor de volver a intentar!'
                    }
                }
                toast.error(msg_error, toast_config)
            }

        }

        cargar_catalogos()

    }

    useEffect(() => {
        cargarCatalogos()
    }, [])


    const guardarSalida = () => {

        const guardar_salida = async() => {

            try {

                if(!descripcion) return toast.error('El campo descripción de salida es necesario!')
                if(!proveedor) return toast.error('El campo proveedor de es necesario!')
                if(!total) return toast.error('El campo total de dinero es necesario!')
                if(!cantidad) return toast.error('El campo cantidad es necesario!')
                if(!fechaSalida) return toast.error('El campo fecha de salida es necesario!')
                if(!tipoPago) return toast.error('El campo tipo de pago es necesario!')

                setSpGuardarSalida(true)

                const d = new Date(fechaSalida)
                const cd = new Date()
                const fecha_registro = `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()} ${cd.getUTCHours()}:${cd.getUTCMinutes()}:${cd.getUTCSeconds()}`
                
                const salida = {
                    descripcion,
                    id_proveedor: proveedor,
                    total,
                    cantidad,
                    fecha_registro,
                    tipo_pago: tipoPago 
                }

                const guardando = await apiMo.post('/guardar-salida-dinero',salida,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })
                setSpGuardarSalida(false)

                Swal.fire({
                    icon: 'success',
                    title: 'Enhorabuena!',
                    text: guardando.data.mensaje
                }).then(result => {
                    navigate('/home')
                })

                
            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                        msg_error = 'Ocurrió un error al guardar salida, favor de volver a intentar!'
                    }
                }
                toast.error(msg_error, toast_config)
                setSpGuardarSalida(false)
            }

        }

        guardar_salida()


    }

    return(
        <>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <h3>Salida de Dinero</h3>
                                <hr />
                                <TextField className="mx-2 mb-3" style={{'width': '45%'}} value={descripcion} onChange={(e) => setDescripcion(e.target.value.toUpperCase())} variant="standard" label='Descripción Salida'/>
                                <FormControl className="mx-2 mb-3" variant="standard" sx={{ minWidth: '45%' }}>
                                    <InputLabel id="articulos-label">Proveedores</InputLabel>
                                    <Select
                                        labelId="articulos-label"
                                        id="articulos-label"
                                        value={proveedor}
                                        onChange={(e) => setProveedor(e.target.value)}
                                        label="Proveedores"
                                    >
                                        {catProveedores && catProveedores.map((prov,i) => (
                                            <MenuItem value={prov.id_proveedor} key={prov.id_proveedor}>{prov.proveedor}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl className="mx-2 mb-3" variant="standard" sx={{ minWidth: '45%' }}>
                                    <InputLabel id="articulos-label">Tipo Pago</InputLabel>
                                    <Select
                                        labelId="articulos-label"
                                        id="articulos-label"
                                        value={tipoPago}
                                        onChange={(e) => setTipoPago(e.target.value)}
                                        label="Proveedores"
                                    >
                                        {catTipoPago && catTipoPago.map((tp,i) => (
                                            <MenuItem value={tp.id_tipo_pago} key={tp.tipo_pago}>{tp.tipo_pago}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField type="number" className="mx-2 mb-3" style={{'width': '45%'}} value={total} onChange={(e) => setTotal(Number(e.target.value))} variant="standard" label='Total Salida'/>
                                <TextField type="number" className="mx-2 mb-3" style={{'width': '45%'}} value={cantidad} onChange={(e) => setCantidad(Number(e.target.value))} variant="standard" label='Cantidad Producto'/>

                                <input className="form-control mb-3" type="date" value={fechaSalida} onChange={(e) => setFechaSalida(e.target.value)} />
                                
                                <hr />
                                <br />
                                <Button disabled={spGuardarSalida} variant="contained" onClick={guardarSalida}>
                                    Guardar
                                    {spGuardarSalida && 
                                        <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Salidas