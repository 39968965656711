import { FormControl, InputLabel, MenuItem, Select, Button } from "@mui/material"
import { useState } from "react"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import apiMo from "../../axios/axios";
import { useLoginStore } from "../../store/zustand";

const Reportes = () => {

    const [tipoReporte, setTipoReporte] = useState('')
    const [fechaI, setFechaI] = useState('')
    const [fechaF, setFechaF] = useState('')
    const [spGenerarReporte, setSpGenerarReporte] = useState(false)

    const { loginStore } = useLoginStore()

    const generarReporte = () => {

        const generar_reporte = async() => {

            try {

                setSpGenerarReporte(true)

                //validaciones
                const fi = new Date(fechaI)
                const ff = new Date(fechaF)

                if(fi > ff) return toast.error('La fecha inicial no puede ser mayor a la fecha final', toast_config)
                if(!tipoReporte) return toast.error('Tiene que seleccionar el reporte a generar', toast_config)

                const reporte = {
                    fechaI, fechaF
                }

                const generar = await apiMo.post(`/generar-reporte/${tipoReporte}`,reporte,{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setSpGenerarReporte(false)

                let byteCharacters = atob(generar.data);
                let byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);

                let file = new Blob([byteArray], { type: 'application/pdf' });
                let fileURL = URL.createObjectURL(file);

                window.open(fileURL, '_blank')

                //mensaje
            
            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                        msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                setSpGenerarReporte(false)
            }

        }

        generar_reporte()

    }

    return(
        <>
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <div className="row">
                                    {/**REPORTE GENERAL ENTRADA Y SALIDAS DE DINERO, CAUNTO GENERO ENTRE QUE FECHAS*/}
                                    {/**REPORTE DE VENTAS DE FECHAS*/}
                                    <h3>Generar Reporte</h3>
                                    <hr />
                                    <div className="col-md-12 mb-3">
                                    <FormControl variant="standard" sx={{ minWidth: '25%' }}>
                                        <InputLabel id="select-label">Reporte</InputLabel>
                                            <Select
                                                labelId="select-label"
                                                id="select-label"
                                                value={tipoReporte}
                                                onChange={(value) => setTipoReporte(value.target.value)}
                                                label="Reporte"
                                                name='tipo_reporte'
                                            >
                                                <MenuItem value={1}>ENTRADAS Y SALIDAS DE DINERO</MenuItem>
                                                <MenuItem value={2}>VENTAS</MenuItem>
                                                        
                                            </Select>
                                    </FormControl>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="" className="fw-bold">Fecha Inicial:</label>
                                        <input type="date" value={fechaI} onChange={(e) => setFechaI(e.target.value)} style={{'width': '75%'}} className="form-control" />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="" className="fw-bold">Fecha Final:</label>
                                        <input type="date" value={fechaF} onChange={(e) => setFechaF(e.target.value)} style={{'width': '75%'}} className="form-control" />
                                    </div>

                                    <div className="col-md-12 d-flex justify-content-center">
                                        <Button disabled={spGenerarReporte} variant="contained" style={{'width' : '50%'}} className="mb-3" onClick={generarReporte}>
                                            Generar Reporte
                                            {spGenerarReporte && 
                                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                        </Button>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
        </>
    )

}

export default Reportes