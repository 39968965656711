import { Link, useNavigate } from "react-router-dom"
import { useLoginStore } from "../../store/zustand"
import { Button } from "@mui/material"
import LogoutIcon from '@mui/icons-material/Logout';
import { useEffect } from "react";

const HeaderColaboradores = () => {

    const { loginStore, limpiarAuth, guardarAuth } = useLoginStore()

    const navigate = useNavigate()

    const cerrarSesion = () => {
        localStorage.removeItem('token')
        limpiarAuth()
        return navigate('/iniciar-sesion')
    }

    useEffect(() => {
        if(!loginStore.auth){
            return navigate('/iniciar-sesion')
        }
    }, [])

    return(
        <div>
            <nav className="navbar nav-header1" >
                <div className="row">
                    <div className="col-md-3 ms-5">
                        <Link to={'/home'}>
                            <img src="/img/logowbg.png" width={'450px'} alt="" className="maxw" />
                        </Link>
                    </div>
                </div>    
            </nav>

        <nav className="navbar navbar-expand-md shadow-lg nav-header2 d-flex justify-content-end" >
            
                <Button variant="contained" className="me-5" onClick={cerrarSesion}> Hola {/*loginStore.nombre_completo.split(' ')[0]*/ 'Oscar'} <LogoutIcon/></Button>
            
    
        </nav>
    
        </div>
        
    )
}

export default HeaderColaboradores