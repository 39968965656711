
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { useEffect, useState } from "react"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import apiMo from "../../axios/axios";
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../store/zustand"


const CrearUsuario = () => {

    const { loginStore } = useLoginStore()

    const [spCrearUsuario, setSpCrearUsuario] = useState(false)
    const [nombre, setNombre] = useState('')
    const [paterno, setPaterno] = useState('')
    const [materno, setMaterno] = useState('')
    const [usuario, setUsuario] = useState('')
    const [fechaN, setFechaN] = useState('')
    const [contrasena, setContrasena] = useState('')
    const [confirmarContrasena, setConfirmarContrasena] = useState('')

    const [catRol, setCatRol] = useState([])
    const [rol, setRol] = useState(0)

    const navigate = useNavigate()
 
    const crearUsuario = async() => {

        let var_name = ''
        if(!nombre) var_name = 'Nombre'
        if(!paterno) var_name = 'Paterno'
        if(!materno) var_name = 'Materno'
        if(!usuario) var_name = 'Usuario'
        if(!fechaN) var_name = 'Fecha de Nacimiento'
        if(!contrasena) var_name = 'Contraseña'
        if(!confirmarContrasena) var_name = 'Confirmar Contraseña'
        if(!rol) var_name = 'Rol'


        if(var_name) return toast.error(`El campo ${var_name} es necesario`, toast_config)

        if(contrasena !== confirmarContrasena) return toast.error(`Las contraseñas no coinciden`, toast_config)

        try {

            setSpCrearUsuario(true)

            const usr = {
                nombre, paterno, materno, usuario, fecha_nacimiento : fechaN, contrasena, id_rol : rol
            }

            const creando = await apiMo.post('/crear-usuario',usr,{
                headers: {
                    'Authorization' : `Bearer ${loginStore.token}`,
                    'Accept': 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })

            setSpCrearUsuario(false)

            Swal.fire({
                icon: 'success',
                title: 'Enhorabuena!',
                text: creando.data.mensaje
            }).then(result => {
                navigate('/crear-usuario')
            })
            
        } catch (error: any) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.error){
                    msg_error = error.response.data.error
                }else{
                    msg_error = 'Ocurrió un error al cargar catalogos, favor de volver a cargar!'
                }
            }
            //mensaje de toastify
            toast.error(msg_error, toast_config)
            setSpCrearUsuario(false)
        }

    }

    const leerCatalogos = () => {

        const catalogos = async() => {

            try {

                const cargando = await apiMo.get('/leer-catalogos-crearusuario',{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setCatRol(cargando.data.cat_rol)
                
            } catch (error:any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                        msg_error = 'Ocurrió un error al cargar catalogos, favor de volver a cargar!'
                    }
                }
            //mensaje de toastify
            toast.error(msg_error, toast_config)
            }

        }

        catalogos()

    }

    useEffect(() => {

        leerCatalogos()

    }, [])

    return(
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormControl  variant="standard" sx={{ minWidth: '100%' }}>
                                            <InputLabel id="select-label">Rol</InputLabel>
                                            <Select
                                            labelId="select-label"
                                            id="select-label"
                                            value={rol}
                                            onChange={(value) => setRol(Number(value.target.value))}
                                            label="Rol"
                                            name='rol'
                                            >
                                                <MenuItem disabled key={0} value={0}></MenuItem>
                                                {catRol && catRol.map((item: {id_articulo: number, nombre_articulo: string}) => (
                                                    <MenuItem key={item.id_articulo} value={item.id_articulo}>{item.nombre_articulo}</MenuItem>
                                                ))}
                                                
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4">
                                        <TextField value={nombre} onChange={(e) => setNombre(e.target.value)} variant="standard" label="Nombre"/>
                                    </div>
                                    <div className="col-md-4">
                                        <TextField value={paterno} onChange={(e) => setPaterno(e.target.value)} variant="standard" label="Paterno"/>
                                    </div>
                                    <div className="col-md-4">
                                        <TextField value={materno} onChange={(e) => setMaterno(e.target.value)} variant="standard" label="Materno"/>
                                    </div>
                                    <div className="col-md-6">
                                        <TextField value={usuario} onChange={(e) => setUsuario(e.target.value)} variant="standard" label="Usuario"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="fn" className="fw-bold">Fecha de Nacimiento</label>
                                        <input value={fechaN} onChange={(e) => setFechaN(e.target.value)} id="fn" type="date" />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField value={contrasena} onChange={(e) => setContrasena(e.target.value)} type="password" variant="standard" label="Contraseña"/>     
                                    </div>
                                    <div className="col-md-6"> 
                                        <TextField value={confirmarContrasena} onChange={(e) => setConfirmarContrasena(e.target.value)} type="password" variant="standard" label="Confirmar Contraseña"/> 
                                    </div>
                                    <div className="col-md-6">
                                        <Button disabled={spCrearUsuario} variant="contained" onClick={crearUsuario}>
                                            Crear Usuario
                                            {spCrearUsuario && 
                                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                        </Button>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>


        </>
    )


}

export default CrearUsuario