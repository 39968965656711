
const Lentes = () => {

    return(
        <div className="container my-3">
            <div className="row">
                <div className="col-md-12 text-center titulo2 mb-3">
                    <span className="fw-bold">TE OFRECEMOS</span>
                </div>
                <div className="col-md-4 text-center">
                    <img src="/img/lentes_oftalmicos.png" alt="" className="maxw" width={'450px'} />
                    <h5>LENTES PARA LA VISTA</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
                <div className="col-md-4 text-center">
                    <img src="/img/examen_vista.png" alt="" className="maxw" width={'350px'} />
                    <h5>EXAMEN DE LA VISTA</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
                <div className="col-md-4 text-center">
                    <img src="/img/mas_lentes.png" alt="" className="maxw" width={'450px'} />
                    <h5>MAS LENTES</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
            </div>
        </div>
    )

}

export default Lentes