import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Table, TableContainer, TableHead, TableBody, TableRow, TextField, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import apiMo from "../../axios/axios"
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../store/zustand"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

interface iArticulo{
    id_articulo: number,
    cantidad: number,
    precio: number,
    descuento: number,
    descripcion: string,
    nombre: string,
    total_descuento: number
}

interface iPaquete{
    id_paquete: number,
    cantidad: number,
    precio: number,
    descuento: number,
    descripcion: string,
    nombre: string,
    total_descuento: number
}

interface iCantidadPartida{
    partida: string,
    cantidad: number
}

interface iPartidaA{
    id_articulo: number; 
    codigo_articulo: string; 
    nombre_articulo: string; 
    descripcion_articulo: string; 
    articulo_contable: string; 
    cantidad_inventario : number; 
    precio_articulo: number;
    cantidad: number,
    descuento: number
}

const GenerarVenta = () => {

    const { loginStore } = useLoginStore()

    const [tipoVenta, setTipoVenta] = useState(0)
    const [catTipoVenta, setCatTipoVenta] = useState([])
    const [catConvenios, setCatConvenios] = useState([])
    const [convenio, setConvenio] = useState(0)

    const [articulos, setArticulos] = useState<iPartidaA[]>([])
    const [paquetes, setPaquetes] = useState([])

    const [mArticulos, setMarticulos] = useState(false)
    const [mPaquetes, setMpaquetes] = useState(false)
    const [aArticulos, setAarticulos] = useState<iArticulo[]>([])
    const [aPaquetes, setAPaquetes] = useState<iPaquete[]>([])
    const [cantidadPartida, setCantidadPartida] = useState<iCantidadPartida[]>([])
    const [totalVenta, setTotalVenta] = useState(0)
    const [totalVentaAhorro, setTotalVentaAhorro] = useState(0)
    const [subTotalVenta, setSubTotalVenta] = useState(0)

    const [spGenerarVenta, setSpGenerarVenta] = useState(false)
    const [nombreCliente, setNombreCliente] = useState('')
    const [tipoPago, setTipoPago] = useState(0)
    const [catTipoPago, setCatTipoPago] = useState([])
    const [descuentoConsulta, setDescuentoConsulta] = useState(false)
    
    const navigate = useNavigate()

    const leerCatalogos = () => {

        const catalogos = async() => {

            try {

                const cargando = await apiMo.get('/leer-catalogos-gventa',{
                    headers: {
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setCatTipoVenta(cargando.data.cat_tv)
                setArticulos(cargando.data.cat_arts)
                setPaquetes(cargando.data.cat_paqs)
                setCatConvenios(cargando.data.cat_conv)
                setCatTipoPago(cargando.data.cat_tipo_pago)
    
            } catch (error: any) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.error){
                        msg_error = error.response.data.error
                    }else{
                            msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

            
        }

        catalogos()

    }

    useEffect(()=>{
        leerCatalogos()
    }, [])

    const buscarArticulos = (e: any) => {

        if(e.target.value.length > 3){

            const articulos = async() => {

                try {
                    
                    const b64na = btoa(e.target.value) 

                    const buscando = await apiMo.get(`/buscar-articulo-coincidencia/${b64na}`,{
                        headers: {
                            'Authorization' : `Bearer ${loginStore.token}`,
                            'Accept': 'application/x-www-form-urlencoded',
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    })

                    setArticulos(buscando.data)

                } catch (error: any) {
                    let msg_error = ''
                    if(error.response){
                        if(error.response.data.error){
                            msg_error = error.response.data.error
                        }else{
                                msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                        }
                    }
                    //mensaje de toastify
                    toast.error(msg_error, toast_config)
                }

            }

            articulos()

        }

    }

    const buscarPaquetes = (e: any) => {

        if(e.target.value.length > 3){
            
            const paquetes = async() => {

                try {
                    
                    const b64np = btoa(e.target.value)

                    const buscando = await apiMo.get(`/buscar-paquete-coincidencia/${b64np}`,{
                        headers: {
                            'Authorization' : `Bearer ${loginStore.token}`,
                            'Accept': 'application/x-www-form-urlencoded',
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    })

                    setPaquetes(buscando.data)

                } catch (error: any) {
                    let msg_error = ''
                    if(error.response){
                        if(error.response.data.error){
                            msg_error = error.response.data.error
                        }else{
                                msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                        }
                    }
                    //mensaje de toastify
                    toast.error(msg_error, toast_config)
                }

            }

            paquetes()
        }

    }

    const guardarPartida = (id:number, partida: string, precio: number, descripcion: string, nombre: string, cantidad: number, descuento : number) => {

        //let cantidad = 1 // prueba

        /*cantidadPartida.forEach(cp => {
            if(cp.partida === partida){
                cantidad = cp.cantidad
                precio = precio * cantidad
            }
        })*/
        if(!cantidad || cantidad <= 0){
            setMarticulos(false)
            setMpaquetes(false)
            return toast.warning('La cantidad no puede ser 0', toast_config)
        }

        if(partida.startsWith('A')){
            const agregado = aArticulos.some(a => a.id_articulo === id)
            if(agregado){
                setMarticulos(false)
                setMpaquetes(false)
                return toast.error('Articulo ya agregado, si desea agregar mas elimine la partida y agregue la cantidad correcta')
            }
        }else{
            const agregado = aPaquetes.some(a => a.id_paquete === id)
            if(agregado){
                setMarticulos(false)
                setMpaquetes(false)
                return toast.error('Paquete ya agregado, si desea agregar mas elimine la partida y agregue la cantidad correcta')
            }
        }

        precio = precio * cantidad
        //console.log(descuento)
        descuento = descuento ? descuento : 0 // prueba
        //descuento = 5
        let descuento_total = 0
        let descontar_global = 0
        if(descuento > 0){
            descontar_global = ((descuento * precio) / 100) * cantidad
            descuento_total = ((precio * cantidad) - descontar_global)
        }else{
            descuento_total = precio * cantidad
        }
        
        setTotalVenta(totalVenta + descuento_total)
        setTotalVentaAhorro(totalVentaAhorro + descontar_global)
        setSubTotalVenta(subTotalVenta + (precio * cantidad))

        if(partida.startsWith('A')){
            setAarticulos([...aArticulos, {id_articulo: id, nombre: nombre, descripcion: descripcion, cantidad: cantidad, precio: precio, descuento: descuento, total_descuento: descuento_total}])
        }else{
            setAPaquetes([...aPaquetes, {id_paquete: id, nombre: nombre, descripcion: descripcion, cantidad: cantidad, precio: precio, descuento: descuento, total_descuento: descuento_total}])
        }

        setMarticulos(false)
        setMpaquetes(false)

    }

    const handleCantidadPartida = (e: any, inventario: number, operacion: number, partida: string, art_paq: number) => {

        

        //saber si ya se agrego la cantidad de la partida
        /*const found = cantidadPartida.find((item) => item['partida'] === partida)
        if(found){*/

            if(operacion === 1){

                if(partida.startsWith('A')){
                    setArticulos((prevArticulos:any) => 
                        prevArticulos.map((articulo :any) =>
                            articulo.id_articulo === art_paq ? {...articulo, cantidad: !articulo.cantidad || articulo.cantidad === 0 ? 1 : articulo.cantidad + 1} : articulo
                        )
                    )

                }else{
                    setPaquetes((prevPaquetes:any) => 
                        prevPaquetes.map((paquete :any) =>
                            paquete.id_paquete === art_paq ? {...paquete, cantidad: !paquete.cantidad || paquete.cantidad === 0 ? 1 : paquete.cantidad + 1} : paquete
                        )
                    )
                }

                
            }else{
                if(partida.startsWith('A')){
                    setArticulos((prevArticulos: any) =>
                        prevArticulos.map((articulo:any) =>
                            articulo.id_articulo === art_paq ? { ...articulo, cantidad: Math.max(0, articulo.cantidad - 1) } : articulo
                    )
                );
                }else{
                    setPaquetes((prevPaquetes: any) =>
                        prevPaquetes.map((paquete:any) =>
                            paquete.id_paquete === art_paq ? { ...paquete, cantidad: Math.max(0, paquete.cantidad - 1) } : paquete
                    )
                );
                }
                
            }



            /*const index = cantidadPartida.indexOf(found)
            if(operacion === 0){
                if(cantidadPartida[index].cantidad - 1 <= 0){
                    cantidadPartida[index].cantidad = 0
                }else{
                    cantidadPartida[index].cantidad = cantidadPartida[index].cantidad - 1
                }
            }else{
                if(cantidadPartida[index].cantidad + 1 > inventario){
                    toast.warning('La cantidad seleccionada es mayor al inventario actual!')
                }
                cantidadPartida[index].cantidad = cantidadPartida[index].cantidad + 1
            }*/

            //setCantidadPartida(cantidadPartida) //dice esto pero no creo
        //}


        //setCantidadPartida([...cantidadPartida, {partida: partida, cantidad: 1}])

    }

    const descuentoArticulo = (e:any, id_articulo: number) => {
        const nuevoDescuento = parseInt(e.target.value) || 0;

        /*if(nuevoDescuento > 10){
            toast.info('El descuento no puede ser mayor al 10%!', toast_config)
            nuevoDescuento = 10
        }*/

        setArticulos((prevArticulos : any) =>
            prevArticulos.map((articulo  : any) =>
                articulo.id_articulo === id_articulo ? { ...articulo, descuento: nuevoDescuento } : articulo
        ));
        
    }

    const generarVenta = () => {
        //validaciones
        if(totalVenta <= 0){
            return toast.error('Aun no se han seleccionado partidas!', toast_config)
        }

        if(tipoVenta <= 0){
            return toast.error('Es necesario seleccionar un tipo de venta!', toast_config)

        }

        if(!nombreCliente) return toast.error('Es necesario el nombre del cliente!', toast_config)

        if(!tipoPago) return toast.error('Es necesario seleccionar el tipo de pago!', toast_config)

       
            const generandoVenta = async() => {
                
                try {

                    Swal.fire({
                        title: 'Estas seguro?',
                        text: "Se va a generar venta!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Si, generar!'
                      }).then(async(result) => {
                        if(result.isConfirmed){
                            setSpGenerarVenta(true)
                            const cd = new Date()
                            const scd = `${cd.getFullYear()}-${cd.getMonth() + 1}-${cd.getDate()} ${cd.getHours()}:${cd.getMinutes()}:${cd.getSeconds()}`
        
                            let estatus_venta = 0
                            let cantidad_pagada = 0
                            if(tipoVenta === 1){
                                estatus_venta = 1
                                cantidad_pagada = totalVenta
                            }else{
                                estatus_venta = 2
                            }
        
                            const id_usr = loginStore.jtoken.id_usuario

                            const venta = {
                                fecha_venta: scd,
                                cliente: nombreCliente,
                                id_usr, 
                                total_venta: totalVenta,
                                id_estatus_venta: estatus_venta,
                                id_tipo_venta: tipoVenta,
                                id_convenio: convenio <= 0 ? null : convenio,
                                cantidad_pagada : cantidad_pagada,
                                apartidas: [],
                                id_tipo_pago : tipoPago
                            }
        
                            //falta las apartidas, que serian todas las partidas
                            let partidas: any = []
        
                            aArticulos.forEach(art => {
                                partidas.push({
                                    id_articulo: art.id_articulo,
                                    cantidad: art.cantidad,
                                    total: art.total_descuento,
                                    descuento: art.descuento
                                })
                            })
        
                            aPaquetes.forEach(paq => {
                                partidas.push({
                                    id_paquete: paq.id_paquete,
                                    cantidad: paq.cantidad,
                                    total: paq.total_descuento,
                                    descuento: paq.descuento
                                })
                            })
        
                            venta.apartidas = partidas
        
                            const generando =await apiMo.post('/registrar-venta',venta,{
                                headers: {
                                    'Authorization' : `Bearer ${loginStore.token}`,
                                    'Accept': 'application/x-www-form-urlencoded',
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                }
                                
                            })
                            setSpGenerarVenta(false)

                            //mandar pdf de ticket con folio
                            //generar pdf
                            if(generando.data.pdf){
                                let byteCharacters = atob(generando.data.pdf);
                                let byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                let byteArray = new Uint8Array(byteNumbers);
    
                                let file = new Blob([byteArray], { type: 'application/pdf' });
                                let fileURL = URL.createObjectURL(file);
    
                                window.open(fileURL, '_blank')
                             } 
                            

                            Swal.fire({
                                icon: 'success',
                                title: 'Enhorabuena!',
                                text: generando.data.mensaje
                            }).then((result) => {
                                navigate('/home')
                            })            

                        }
                      }).catch((error) => {
                        let msg_error = ''
                        if(error.response){
                            if(error.response.data.error){
                                msg_error = error.response.data.error
                            }else{
                                    msg_error = 'Ocurrió un error al generar venta, favor de volver a intentar!'
                            }
                        }
        
                        //mensaje de toastify
                        toast.error(msg_error, toast_config)
                        setSpGenerarVenta(false)
                    });

                    

                } catch (error : any) {
                    let msg_error = ''
                    if(error.response){
                        if(error.response.data.error){
                            msg_error = error.response.data.error
                        }else{
                                msg_error = 'Ocurrió un error al agregar articulo, favor de volver a cargar!'
                        }
                    }
                    //mensaje de toastify
                    toast.error(msg_error, toast_config)
                    setSpGenerarVenta(false)
                }
    
            }

            generandoVenta()
        
    }

    const descontarConsulta = () => {

        if(descuentoConsulta) return toast.info('Ya se realizó descuento de consulta!', toast_config)

        //tal vez dar de alta como articulo la consulta y asi descontar O dar la opcion de dar descuento fijo a algun articulo por ejemplo armazon y descontar los 200
        if(aArticulos.length > 0){   
            // Hacer una copia del array de artículos para no mutar el estado directamente
            const nuevosArticulos = [...aArticulos];

            // Iterar sobre los artículos
            for (let i = 0; i < nuevosArticulos.length; i++) {
            // Verificar si el precio es mayor a 200
                if (nuevosArticulos[i].total_descuento > 200) {
                    // Descontarle 200 al precio
                    const descuento_porcentaje = (200 * 100) / nuevosArticulos[i].precio
                    nuevosArticulos[i].descuento = Number(descuento_porcentaje.toFixed(2))
                    nuevosArticulos[i].total_descuento = nuevosArticulos[i].precio - 200
                    setDescuentoConsulta(true)
                    setTotalVenta(totalVenta - 200)
                    setTotalVentaAhorro(totalVentaAhorro + 200)
                    break;
                }
            }

            // Actualizar el estado con los nuevos artículos
            setAarticulos(nuevosArticulos);
        }else{
            if(aPaquetes.length > 0){
                const nuevosPaquetes = [...aPaquetes]

                for(let i=0; i<nuevosPaquetes.length; i++){
                    if (nuevosPaquetes[i].total_descuento > 200) {
                        // Descontarle 200 al precio
                        const descuento_porcentaje = (200 * 100) / nuevosPaquetes[i].precio
                        nuevosPaquetes[i].descuento = Number(descuento_porcentaje.toFixed(2))
                        nuevosPaquetes[i].total_descuento = nuevosPaquetes[i].precio - 200
    
                        setTotalVenta(totalVenta - 200)
                        setTotalVentaAhorro(totalVentaAhorro + 200)                      
                        setDescuentoConsulta(true)
                        break;
                    }
                }

                // Actualizar el estado con los nuevos artículos
                setAPaquetes(nuevosPaquetes);
            }else{
                toast.info('No hay una partida para descontar consulta', toast_config)
            }

        }

        

    }

    const eliminarPartida = (e:any, partida:string, id:number) => {

        if(partida.startsWith('A')){
            const nuevoArray = aArticulos.filter(a => a.id_articulo !== id)
            const ae = aArticulos.filter(a => a.id_articulo === id)

            setTotalVenta(totalVenta - ae[0].total_descuento)

            const descontar_global = ((ae[0].descuento * ae[0].precio) / 100) * ae[0].cantidad
            setTotalVentaAhorro(totalVentaAhorro - descontar_global)
            setSubTotalVenta(subTotalVenta - (ae[0].precio * ae[0].cantidad))

            setAarticulos(nuevoArray)
        }else{
            const nuevoArray = aPaquetes.filter(p => p.id_paquete !== id)
            const pe = aPaquetes.filter(p => p.id_paquete === id)

            setTotalVenta(totalVenta - pe[0].total_descuento)

            const descontar_global = ((pe[0].descuento * pe[0].precio) / 100) * pe[0].cantidad
            setTotalVentaAhorro(totalVentaAhorro - descontar_global)
            setSubTotalVenta(subTotalVenta - (pe[0].precio * pe[0].cantidad))

            setAPaquetes(nuevoArray)
        }



    }

    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <FormControl variant="standard" sx={{ minWidth: '75%' }}>
                                            <InputLabel id="select-label">Tipo de Venta</InputLabel>
                                                <Select
                                                    labelId="select-label"
                                                    id="select-label"
                                                    value={tipoVenta}
                                                    onChange={(value) => setTipoVenta(Number(value.target.value))}
                                                    label="Tipo de Venta"
                                                    name='tipo_venta'
                                                >
                                                    <MenuItem disabled key={0} value={0}></MenuItem>
                                                    {catTipoVenta && catTipoVenta.map((item: {id_tipo_venta: number, tipo_venta: string}) => (
                                                        <MenuItem key={item.id_tipo_venta} value={item.id_tipo_venta}>{item.tipo_venta}</MenuItem>
                                                    ))}
                                                        
                                                </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <FormControl variant="standard" sx={{ minWidth: '75%' }}>
                                            <InputLabel id="select-label">Tipo de Pago</InputLabel>
                                                <Select
                                                    labelId="select-label"
                                                    id="select-label"
                                                    value={tipoPago}
                                                    onChange={(value) => setTipoPago(Number(value.target.value))}
                                                    label="Tipo de Pago"
                                                    name='tipo_pago'
                                                >
                                                    <MenuItem disabled key={0} value={0}></MenuItem>
                                                    {catTipoPago && catTipoPago.map((item: {id_tipo_pago: number, tipo_pago: string}) => (
                                                        <MenuItem key={item.id_tipo_pago} value={item.id_tipo_pago}>{item.tipo_pago}</MenuItem>
                                                    ))}
                                                        
                                                </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <FormControl variant="standard" sx={{ minWidth: '75%' }}>
                                            <InputLabel id="select-label">Convenio</InputLabel>
                                                <Select
                                                    labelId="select-label"
                                                    id="select-label"
                                                    value={convenio}
                                                    onChange={(value) => setConvenio(Number(value.target.value))}
                                                    label="Convenio"
                                                    name='id_convenio'
                                                >
                                                    <MenuItem disabled key={0} value={0}></MenuItem>
                                                    {catConvenios && catConvenios.map((item: {id_convenio: number, convenio: string}) => (
                                                        <MenuItem key={item.id_convenio} value={item.id_convenio}>{item.convenio}</MenuItem>
                                                    ))}
                                                        
                                                </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <TextField style={{'width': '75%'}} onChange={(value) => setNombreCliente(value.target.value.toString().toUpperCase())} value={nombreCliente} id="standard-basic" label="Nombre Cliente" variant="standard" />               
                                    </div>
                                    <div className="col-md-4 d-flex justify-content-center mb-3">
                                        <Button variant="contained" onClick={() => setMarticulos(true)}>Buscar Articulos</Button>
                                    </div>
                                    <div className="col-md-4 d-flex justify-content-center mb-3">
                                        <Button variant="contained" onClick={() => setMpaquetes(true)}>Buscar Paquetes</Button>
                                    </div>
                                    <div className="col-md-4 d-flex justify-content-center mb-3">
                                        <Button variant="contained" onClick={descontarConsulta}>Descontar Consulta</Button>
                                    </div>

                                    {/** PARTIDAS AGREGADAS*/}
                                      
                                     <div className="col-md-12 shadow border" id="dPartidas">
                                        <TableContainer component={Paper} className="my-2">
                                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center"></StyledTableCell>
                                                        <StyledTableCell align="center">Partida</StyledTableCell>
                                                        <StyledTableCell align="center">Nombre</StyledTableCell>
                                                        <StyledTableCell align="center">Descripcion</StyledTableCell>
                                                        <StyledTableCell align="center">Cantidad</StyledTableCell>
                                                        <StyledTableCell align="center">Precio</StyledTableCell>
                                                        <StyledTableCell align="center">Descuento</StyledTableCell>
                                                        <StyledTableCell align="center">Total</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                        
                                                    {aArticulos && aArticulos.map((articulo: iArticulo) => (
                                                        <StyledTableRow key={'A' + articulo.id_articulo} >
                                                            <StyledTableCell  className="text-center text-danger" >
                                                                <DeleteOutlineIcon style={{'cursor' : 'pointer'}} onClick={(e) => eliminarPartida(e, `A${articulo.id_articulo}`, articulo.id_articulo)}/>
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th" scope="row"  className="text-center">
                                                                A{articulo.id_articulo}
                                                            </StyledTableCell>
                                                            <StyledTableCell  className="text-center">{articulo.nombre}</StyledTableCell>
                                                            <StyledTableCell  className="text-center">{articulo.descripcion}</StyledTableCell>
                                                            <StyledTableCell  className="text-center">{articulo.cantidad}</StyledTableCell>
                                                            <StyledTableCell  className="text-center">${Number(articulo.precio).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                                            <StyledTableCell  className="text-center">{articulo.descuento ? `${articulo.descuento}%` : `0%`}</StyledTableCell>
                                                            <StyledTableCell  className="text-center">${Number(articulo.total_descuento).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                                            
                                                        </StyledTableRow>
                                                    ))}
                                                    {aPaquetes && aPaquetes.map((paquete: iPaquete) => (
                                                        <StyledTableRow key={'P' + paquete.id_paquete} >
                                                            <StyledTableCell  className="text-center text-danger" >
                                                                <DeleteOutlineIcon style={{'cursor' : 'pointer'}} onClick={(e) => eliminarPartida(e, `P${paquete.id_paquete}`, paquete.id_paquete)}/>
                                                            </StyledTableCell>
                                                            <StyledTableCell component="th" scope="row">
                                                                P{paquete.id_paquete}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{paquete.nombre}</StyledTableCell>
                                                            <StyledTableCell>{paquete.descripcion}</StyledTableCell>
                                                            <StyledTableCell>{paquete.cantidad}</StyledTableCell>
                                                            <StyledTableCell>{paquete.precio}</StyledTableCell>
                                                            <StyledTableCell>{paquete.descuento}</StyledTableCell>
                                                            <StyledTableCell>{paquete.total_descuento}</StyledTableCell>
                                                            
                                                        </StyledTableRow>
                                                    ))}

                                                    <StyledTableRow key={'subtotal'}  style={{'cursor': 'pointer'}} >
                                                        <StyledTableCell align="right" colSpan={8} className="fw-bold">SubTotal: ${Number(subTotalVenta).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                                    </StyledTableRow>
                                                    {totalVentaAhorro > 0 &&
                                                        <StyledTableRow key={'descuento'}  style={{'cursor': 'pointer'}} >
                                                            <StyledTableCell align="right" colSpan={8} className="fw-bold">Descuento: ${Number(totalVentaAhorro).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                                        </StyledTableRow>
                                                    }
                                                    <StyledTableRow key={'total'} style={{'cursor': 'pointer'}} >
                                                        <StyledTableCell align="right" colSpan={8} className="fw-bold">Total: ${Number(totalVenta).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                                    </StyledTableRow>


                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Button disabled={spGenerarVenta} variant="contained" className="my-2" style={{'width': '25%'}} onClick={generarVenta}>
                                            Generar Venta &nbsp;
                                            {spGenerarVenta && 
                                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                        </Button>
                                     </div>

                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>

                <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>

            {/**MODAL ARTICULOS */}
            <Modal
                open={mArticulos}
                onClose={() => setMarticulos(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >                   
                <Box sx={style}>
                    <div className="col-md-6">
                        <TextField onChange={buscarArticulos} name="articulo" id="standard-basic" style={{'width': '75%'}} className="mb-3" label="Nombre Articulo" variant="standard" />

                    </div>
                    <div className="table-responsive">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Nombre</StyledTableCell>
                                        <StyledTableCell align="center">Descripcion</StyledTableCell>
                                        <StyledTableCell align="center">Inventario</StyledTableCell>
                                        <StyledTableCell align="center">Precio</StyledTableCell>
                                        <StyledTableCell align="center">Asignar Cantidad</StyledTableCell>
                                        <StyledTableCell align="center">Descuento Articulo</StyledTableCell>
                                        <StyledTableCell align="center"></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        
                                    {articulos && articulos.length > 0 ? articulos.map((articulo) => (
                                        <StyledTableRow key={articulo.id_articulo}  >
                                            <StyledTableCell align="center" component="th" scope="row">
                                                {articulo.nombre_articulo}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{articulo.descripcion_articulo}</StyledTableCell>
                                            <StyledTableCell align="center">{articulo.articulo_contable === 'CONTABLE' ? articulo.cantidad_inventario : 'N/A'}</StyledTableCell>
                                            <StyledTableCell align="center">${Number(articulo.precio_articulo).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <RemoveIcon  style={{'cursor': 'pointer'}} onClick={(e) => handleCantidadPartida(e, articulo.cantidad_inventario, 0, `A${articulo.id_articulo}`, articulo.id_articulo)}/> 
                                                    {articulo.cantidad ? articulo.cantidad : 0}
                                                    {/*cantidadPartida.map((cp) => (
                                                        cp.partida === `A${articulo.id_articulo}` ?  cp.cantidad : null
                                                    ))*/}
                                                <AddIcon style={{'cursor': 'pointer'}} onClick={(e) => handleCantidadPartida(e, articulo.cantidad_inventario, 1, `A${articulo.id_articulo}`, articulo.id_articulo)}/>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <TextField type="number" id="standard-basic" label="Descuento" variant="standard"  onChange={(e) => descuentoArticulo(e, articulo.id_articulo)}/>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Button variant="outlined" onClick={() => guardarPartida(articulo.id_articulo, `A${articulo.id_articulo}`, articulo.precio_articulo, articulo.descripcion_articulo, articulo.nombre_articulo, articulo.cantidad, articulo.descuento)}>
                                                    Agregar Partida
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                            )):
                                            <StyledTableRow key={'no encontrado'} >
                                                <StyledTableCell colSpan={7} align="center" className="text-danger fw-bold text-center">No se encontraron coincidencia de articulos.</StyledTableCell>

                                            </StyledTableRow> 
                                            }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Box>
            </Modal>

            {/**MODAL PAQUETES */}
            <Modal
                open={mPaquetes}
                onClose={() => setMpaquetes(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >                   
                <Box sx={style}>
                    <div className="col-md-6">
                        <TextField onChange={buscarPaquetes} name="articulo" id="standard-basic" style={{'width': '75%'}} className="mb-3" label="Nombre Articulo" variant="standard" />

                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Nombre</StyledTableCell>
                                    <StyledTableCell align="center">Descripcion</StyledTableCell>
                                    <StyledTableCell align="center">Armazon</StyledTableCell>
                                    <StyledTableCell align="center">Inventario Armazon</StyledTableCell>
                                    <StyledTableCell align="center">Accesorio</StyledTableCell>
                                    <StyledTableCell align="center">Inventario Accesorio</StyledTableCell>
                                    <StyledTableCell align="center">Micas</StyledTableCell>
                                    <StyledTableCell align="center">Precio Paquete</StyledTableCell>
                                    <StyledTableCell align="center">Asignar Cantidad</StyledTableCell>
                                    {/*<StyledTableCell align="center">Descuento Articulo</StyledTableCell>*/}
                                    <StyledTableCell align="center"></StyledTableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    
                                {paquetes && paquetes.length > 0 ? paquetes.map((paquete: {
                                    id_paquete: number;
                                    nombre_paq: string; 
                                    descripcion_paq: string; 
                                    nombre_articulo_armazon: string; 
                                    inventario_armazon_paq: number; 
                                    nombre_articulo_accesorio: string; 
                                    inventario_accesorio: number; 
                                    nombre_articulo_micas: string; 
                                    precio_paq: number;
                                    cantidad: number}) => (
                                    <StyledTableRow key={paquete.id_paquete} >
                                        <StyledTableCell component="th" scope="row">
                                            {paquete.nombre_paq}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{paquete.descripcion_paq}</StyledTableCell>
                                        <StyledTableCell align="center">{paquete.nombre_articulo_armazon}</StyledTableCell>
                                        <StyledTableCell align="center">{paquete.inventario_armazon_paq}</StyledTableCell>
                                        <StyledTableCell align="center">{paquete.nombre_articulo_accesorio}</StyledTableCell>
                                        <StyledTableCell align="center">{paquete.inventario_accesorio}</StyledTableCell>
                                        <StyledTableCell align="center">{paquete.nombre_articulo_micas}</StyledTableCell>
                                        <StyledTableCell align="center">${paquete.precio_paq}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <RemoveIcon style={{'cursor': 'pointer'}} onClick={(e) => handleCantidadPartida(e, paquete.id_paquete, 0, `P${paquete.id_paquete}`, paquete.id_paquete)}/> 
                                                {paquete.cantidad ? paquete.cantidad : 0}
                                                {/*cantidadPartida.map((cp) => (
                                                    cp.partida === `P${paquete.id_paquete}` ? cp.cantidad : null
                                                ))*/} 
                                            <AddIcon style={{'cursor': 'pointer'}} onClick={(e) => handleCantidadPartida(e, paquete.id_paquete, 1, `P${paquete.id_paquete}`, paquete.id_paquete)}/>
                                        </StyledTableCell>
                                        {/*<StyledTableCell>
                                            <TextField id="standard-basic" label="Standard" variant="standard" />
                                        </StyledTableCell>*/}
                                        <StyledTableCell align="center">
                                            <Button variant="outlined" onClick={() => guardarPartida(paquete.id_paquete, `P${paquete.id_paquete}`, paquete.precio_paq, paquete.descripcion_paq, paquete.nombre_paq, paquete.cantidad, 0)}>Agregar Partida</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                        )):
                                        <StyledTableRow key={'no encontrado'} >
                                            <StyledTableCell colSpan={11} align="center" className="text-danger fw-bold text-center">No se encontraron coincidencia de paquetes.</StyledTableCell>

                                        </StyledTableRow> 
                                        }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>               


        </>
    )

}

export default GenerarVenta